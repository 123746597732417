<template>
  <form @submit="handleSubmit" class="loan-application-form" autocomplete="off">
    <Input
      name="bot-field"
      label="Don’t fill this out if you're human"
      v-model="formData.honeypot"
      autocomplete="random"
      isHoneyPot
    />
    <Margins>
      <b-row>
        <b-col md="6">
          <Input
            :label="`${$t('FORM.FIRST_NAME')} *`"
            name="firstName"
            autocomplete="given-name"
            v-model.trim="formData.firstName"
            :error="errors.first('firstName')"
            v-validate="{
              required: true,
              regex: REGEX.BIGBANK.EE.name,
            }"
            :data-vv-as="$t('FORM.VALIDATOR.FIRST_NAME')"
          />
        </b-col>
        <b-col md="6">
          <Input
            :label="`${$t('FORM.LAST_NAME')} *`"
            name="surname"
            autocomplete="family-name"
            v-model.trim="formData.surname"
            :error="errors.first('surname')"
            v-validate="{
              required: true,
              regex: REGEX.BIGBANK.EE.name,
            }"
            class="margins__mobile-md-double"
            :data-vv-as="$t('FORM.VALIDATOR.LAST_NAME')"
          />
        </b-col>
      </b-row>

      <Input
        :label="`${$t('FORM.ID_NUMBER')} *`"
        name="personalIdentityCode"
        placeholder="00000000000"
        v-model.trim="formData.personalIdentityCode"
        :error="errors.first('personalIdentityCode')"
        v-validate="'required|personalCodeEEAndLT'"
        class="margins__double"
        :data-vv-as="$t('FORM.VALIDATOR.ID_NUMBER')"
      />

      <b-row class="margins__double">
        <b-col md="6">
          <Input
            type="email"
            :label="`${$t('FORM.EMAIL')} *`"
            name="email"
            autocomplete="email"
            v-model.trim="formData.email"
            :error="errors.first('email')"
            v-validate="{ required: true, email: true }"
            :data-vv-as="$t('FORM.VALIDATOR.EMAIL')"
          />
        </b-col>
        <b-col md="6">
          <MobileInput
            ref="mobileInput"
            :default-country="STORE.EE.country.toUpperCase()"
            mode="international"
            name="mainMobileNo"
            :label="`${$t('FORM.MOBILE')} *`"
            dynamic-placeholder
            :onlyCountries="[
              STORE.EE.country.toUpperCase(),
              COUNTRY.FI.toUpperCase(),
            ]"
            valid-characters-only
            disabled-fetching-country
            :value="numberObject.number.international"
            :error="errors.first('mainMobileNo')"
            v-validate="'required|bigbankPhoneEE'"
            @input="changeMobileNumber"
            @validate="populateNumberObject"
            class="margins__mobile-md-double"
            :data-vv-as="$t('FORM.VALIDATOR.MOBILE')"
          />
        </b-col>
      </b-row>

      <b-row class="margins__double">
        <b-col>
          <Select
            name="maritalStatus"
            :label="`${$t('FORM.MARITAL_STATUS')} *`"
            v-model.trim="formData.maritalStatus"
            :options="maritalStatusOptions"
            :error="errors.first('maritalStatus')"
            v-validate="{ required: true }"
            :data-vv-as="$t('FORM.VALIDATOR.MARITAL_STATUS')"
          />
        </b-col>
        <b-col md="2">
          <Select
            name="dependents"
            :label="`${$t('FORM.DEPENDENTS')} *`"
            v-model.trim="formData.dependents"
            :options="dependentsOptions"
            :error="errors.first('dependents')"
            v-validate="{ required: true }"
            class="margins__mobile-md-double"
            :data-vv-as="$t('FORM.VALIDATOR.DEPENDENTS')"
          />
        </b-col>
        <b-col>
          <Select
            name="fieldOfActivity"
            :label="`${$t('FORM.FIELD_OF_ACTIVITY')} *`"
            v-model.trim="formData.employment.fieldOfActivity"
            :options="fieldOfActivityOptions"
            :error="errors.first('fieldOfActivity')"
            v-validate="{ required: true }"
            class="margins__mobile-md-double"
            :data-vv-as="$t('FORM.VALIDATOR.FIELD_OF_ACTIVITY')"
          />
        </b-col>
      </b-row>

      <b-row class="margins__double">
        <b-col md="6">
          <Input
            :label="`${$t('FORM.DECLARED_NET_MONTHLY_AMOUNT')} *`"
            name="declaredNetMonthlyAmount"
            v-model.trim="formData.declaredNetMonthlyAmount"
            :error="errors.first('declaredNetMonthlyAmount')"
            v-validate="{
              required: true,
              regex: REGEX.number,
            }"
            :data-vv-as="$t('FORM.VALIDATOR.DECLARED_NET_MONTHLY_AMOUNT')"
            placeholder="0000"
          />
        </b-col>
        <b-col md="6">
          <Input
            :label="`${$t('FORM.DECLARED_MONTHLY_PAYMENT')} *`"
            name="declaredMonthlyPayment"
            v-model.trim="formData.declaredMonthlyPayment"
            :info="$t('FORM.MONTHLY_LIABILITIES_INFO')"
            :error="errors.first('declaredMonthlyPayment')"
            v-validate="{
              required: true,
              regex: REGEX.number,
            }"
            :data-vv-as="$t('FORM.VALIDATOR.DECLARED_MONTHLY_PAYMENT')"
            placeholder="0000"
            class="margins__mobile-md-double"
          />
        </b-col>
      </b-row>

      <Check
        class="margins__double"
        id="pep-checkbox"
        name="customerIsNotPEP"
        v-model="formData.customerIsNotPEP"
      >
        {{ $t('FORM.CONFIRM_PERSON_IS_NOT_A_PEP') }}
        <Button variant="ghost" width="auto" v-b-toggle="`pep-help`">
          <HelpSVG class="icon" />
        </Button>
      </Check>
      <b-collapse id="pep-help" role="tabpanel">
        <Card color="grey" padding="medium" class="text-small">
          <p v-html="$t('FORM.PEP_CONFIRMATION_HELP_EE')" />
        </Card>
      </b-collapse>

      <Separator hasLine size="large" />

      <Check
        :label="privacyPolicyConsent"
        name="privacyConsent"
        v-model="formData.privacyConsent"
        v-validate="{ required: true }"
      />

      <Check
        :label="$t('FORM.PERSONAL_INFO_CONSENT')"
        name="personalInfoConsent"
        v-model="formData.personalInfoConsent"
        v-validate="{ required: true }"
      />

      <Check
        :label="registryCheckConsent"
        name="allowRegistryCheck"
        v-model="formData.allowRegistryCheck"
        v-validate="{ required: true }"
      />

      <Check
        :label="personalDataProcessingConsent"
        name="agreeToPersonalDataProcessing"
        v-model="formData.agreeToPersonalDataProcessing"
        v-validate="{ required: true }"
      />

      <div
        v-if="
          errors.first('personalInfoConsent') ||
          errors.first('privacyConsent') ||
          errors.first('allowRegistryCheck') ||
          errors.first('agreeToPersonalDataProcessing')
        "
        class="check__error"
      >
        {{ $t('FORM.VALIDATOR.TERMS') }}
      </div>

      <p class="text-small text-center margins__triple">
        {{ $t('FORM.CREDIT_CHECK_INFO') }}
      </p>

      <div class="text-center">
        <Button type="submit">{{ $t('CONTINUE') }}</Button>
      </div>

      <p class="text-small text-center">
        {{ $t('FORM.NO_COMMITMENT_WITH_APPLICATION') }}
      </p>
    </Margins>
  </form>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import { SET_LOAN_APPLICATION_DATA } from '@/store/types';
import {
  Button,
  Check,
  Input,
  Margins,
  Separator,
  Select,
  MobileInput,
  Card,
} from '@/components';
import { constants } from '@/mixins';
import HelpSVG from '@/assets/images/question-circle-solid.svg';
import { STORE } from '@/constants';

export default {
  name: 'LoanApplicationFormEE',
  mixins: [constants],
  components: {
    Input,
    Margins,
    Button,
    Check,
    Separator,
    Select,
    MobileInput,
    HelpSVG,
    Card,
  },
  data() {
    return {
      formData: {
        country: STORE.EE.country.toUpperCase(),
        employment: {
          fieldOfActivity: null,
        },
      },
      numberObject: {
        number: {
          input: '',
          international: '',
        },
      },
    };
  },
  mounted() {
    this.formData = {
      ...this.formData,
      ...this.removeConsents(this.loanApplicationData),
    };
    this.numberObject.number.international = this.loanApplicationData?.mobile;
  },
  methods: {
    ...mapMutations([SET_LOAN_APPLICATION_DATA]),
    removeConsents(object) {
      if (!object) {
        return this.formData;
      }

      const {
        personalInfoConsent,
        privacyConsent,
        allowRegistryCheck,
        agreeToPersonalDataProcessing,
        customerIsNotPEP,
        ...formData
      } = object; // eslint-disable-line no-unused-vars
      return formData;
    },
    async handleSubmit(e) {
      e.preventDefault();
      this.formData.language = this.language;

      if (this.formData.honeypot) {
        return false;
      }

      const valid = await this.$validator.validateAll();
      if (!valid) {
        return false;
      }

      this.formData.mainMobileNo = this.numberObject.number.international
        .replace(/\s/g, '')
        .replace('+', '');
      this.formData.customerIsPEP = !this.formData.customerIsNotPEP;

      const clonedFormData = JSON.parse(JSON.stringify(this.formData));
      clonedFormData.bundleId = this.bundle.id;
      clonedFormData.personalInfoConsent = !!clonedFormData.personalInfoConsent;
      clonedFormData.privacyConsent = !!clonedFormData.privacyConsent;

      const data = {
        ...clonedFormData,
      };

      this.SET_LOAN_APPLICATION_DATA(data);

      this.$router.push({ name: this.ROUTES.LOAN_APPLICATION_LOADING.name });

      this.formData = { employment: {} };
      this.$validator.reset();
    },
    changeMobileNumber(numberObject) {
      const international =
        numberObject.number?.international || numberObject.number.input;
      this.numberObject = {
        ...this.numberObject,
        number: {
          ...this.numberObject.number,
          international,
        },
      };
    },
    populateNumberObject(numberObject) {
      if (!this.numberObject.input) {
        this.numberObject = numberObject;
      }
    },
  },
  computed: {
    ...mapState(['loanApplicationData', 'language']),
    ...mapGetters(['bundle']),
    privacyPolicyConsent() {
      const termsAndConditionsFileName = `TermsAndConditions-EE-${this.$i18n.locale.toUpperCase()}`;
      const policyFileName = `PrivacyPolicy${this.$i18n.locale.toUpperCase()}`;
      return `<p>${this.$t('FORM.PRIVACY_CONSENT', [
        `<a href="${window.location.origin}/documents/${termsAndConditionsFileName}.pdf" target="_blank" rel="noopener noreferrer">`,
        `</a>`,
        `<a href="${window.location.origin}/documents/${policyFileName}.pdf" target="_blank" rel="noopener noreferrer">`,
        `</a>`,
      ])}</p>`;
    },
    registryCheckConsent() {
      return `<p>${this.$t('FORM.ALLOW_REGISTRY_CHECK', [
        `<a href="${this.registryCheckURL}" target="_blank" rel="noopener noreferrer">`,
        `</a>`,
      ])}</p>`;
    },
    registryCheckURL() {
      switch (this.language) {
        case this.LOCALES.ET:
          return 'https://bigbank.ee/jarelmaks-mta/';
        case this.LOCALES.RU:
          return 'https://bigbank.ee/ru/rassrotska-mta/';
        default:
          return 'https://bigbank.ee/jarelmaks-mta/';
      }
    },
    personalDataProcessingConsent() {
      return `<p>${this.$t('FORM.AGREE_TO_PERSONAL_DATA_PROCESSING', [
        `<a href="${this.personalDataProcessingURL}" target="_blank" rel="noopener noreferrer">`,
        `</a>`,
      ])}</p>`;
    },
    personalDataProcessingURL() {
      switch (this.language) {
        case this.LOCALES.ET:
          return 'https://www.bigbank.ee/content/uploads/2017/08/Bigbank_AS_Kliendiandmete_tootlemise_pohimotted.pdf';
        case this.LOCALES.RU:
          return 'https://bigbank.ee/content/uploads/2017/08/Bigbank_AS_Kliendiandmete_tootlemise_pohimotted_RU.pdf';
        default:
          return 'https://www.bigbank.ee/content/uploads/2017/08/Bigbank_AS_Kliendiandmete_tootlemise_pohimotted.pdf';
      }
    },
    maritalStatusOptions() {
      return [
        { value: 'SINGLE', label: this.$t('MARITAL_STATUS.SINGLE') },
        { value: 'MARRIED', label: this.$t('MARITAL_STATUS.MARRIED') },
        {
          value: 'COHABITING',
          label: this.$t('MARITAL_STATUS.COHABITING'),
        },
        { value: 'DIVORCED', label: this.$t('MARITAL_STATUS.DIVORCED') },
        { value: 'WIDOW', label: this.$t('MARITAL_STATUS.WIDOW') },
      ];
    },
    fieldOfActivityOptions() {
      return [
        {
          value: 'PRIVATE_SECTOR',
          label: this.$t('FIELD_OF_ACTIVITY.PRIVATE_SECTOR'),
        },
        {
          value: 'PUBLIC_SECTOR',
          label: this.$t('FIELD_OF_ACTIVITY.PUBLIC_SECTOR'),
        },
        {
          value: 'ENTREPRENEUR',
          label: this.$t('FIELD_OF_ACTIVITY.ENTREPRENEUR'),
        },
        {
          value: 'UNEMPLOYED',
          label: this.$t('FIELD_OF_ACTIVITY.UNEMPLOYED'),
        },
        {
          value: 'PENSIONER',
          label: this.$t('FIELD_OF_ACTIVITY.PENSIONER'),
        },
        { value: 'STUDENT', label: this.$t('FIELD_OF_ACTIVITY.STUDENT') },
        {
          value: 'PARENTAL_LEAVE',
          label: this.$t('FIELD_OF_ACTIVITY.PARENTAL_LEAVE'),
        },
        {
          value: 'INDIVIDUAL_ACTIVITY',
          label: this.$t('FIELD_OF_ACTIVITY.INDIVIDUAL_ACTIVITY'),
        },
      ];
    },
    dependentsOptions() {
      return [
        { value: '0', label: this.$t('NUMBER_OF_DEPENDENTS.0') },
        { value: '1', label: this.$t('NUMBER_OF_DEPENDENTS.1') },
        { value: '2', label: this.$t('NUMBER_OF_DEPENDENTS.2') },
        { value: '3', label: this.$t('NUMBER_OF_DEPENDENTS.3') },
        { value: '4', label: this.$t('NUMBER_OF_DEPENDENTS.4') },
        { value: '5_OR_MORE', label: this.$t('NUMBER_OF_DEPENDENTS.5+') },
      ];
    },
  },
};
</script>
