<template>
  <form @submit="handleSubmit" class="loan-application-form" autocomplete="off">
    <Input
      name="bot-field"
      label="Don’t fill this out if you're human"
      v-model="formData.honeypot"
      autocomplete="random"
      isHoneyPot
    />

    <Margins>
      <b-row>
        <b-col md="6">
          <Input
            :label="`${$t('FORM.FIRST_NAME')} *`"
            name="firstName"
            autocomplete="given-name"
            v-model.trim="formData.firstName"
            :error="errors.first('firstName')"
            v-validate="{
              required: true,
              regex: REGEX.BIGBANK.LT.name,
            }"
            :data-vv-as="$t('FORM.VALIDATOR.FIRST_NAME')"
          />
        </b-col>
        <b-col md="6">
          <Input
            :label="`${$t('FORM.LAST_NAME')} *`"
            name="surname"
            autocomplete="family-name"
            v-model.trim="formData.surname"
            :error="errors.first('surname')"
            v-validate="{
              required: true,
              regex: REGEX.BIGBANK.LT.name,
            }"
            class="margins__mobile-md-double"
            :data-vv-as="$t('FORM.VALIDATOR.LAST_NAME')"
          />
        </b-col>
      </b-row>

      <Input
        :label="`${$t('FORM.ID_NUMBER')} *`"
        name="personalIdentityCode"
        placeholder="00000000000"
        v-model.trim="formData.personalIdentityCode"
        :error="errors.first('personalIdentityCode')"
        v-validate="'required|personalCodeEEAndLT'"
        class="margins__double"
        :data-vv-as="$t('FORM.VALIDATOR.ID_NUMBER')"
      />

      <b-row class="margins__double">
        <b-col md="6">
          <Input
            type="email"
            :label="`${$t('FORM.EMAIL')} *`"
            name="email"
            autocomplete="email"
            v-model.trim="formData.email"
            :error="errors.first('email')"
            v-validate="{ required: true, email: true }"
            :data-vv-as="$t('FORM.VALIDATOR.EMAIL')"
          />
        </b-col>
        <b-col md="6">
          <MobileInput
            ref="mobileInput"
            :default-country="STORE.LT.country.toUpperCase()"
            mode="international"
            name="mainMobileNo"
            :label="`${$t('FORM.MOBILE')} *`"
            dynamic-placeholder
            :preferred-countries="[
              STORE.LT.country.toUpperCase(),
              STORE.LV.country.toUpperCase(),
              STORE.EE.country.toUpperCase(),
            ]"
            valid-characters-only
            disabled-fetching-country
            :value="numberObject.number.international"
            :error="errors.first('mainMobileNo')"
            v-validate="'required|bigbankPhoneLT'"
            @input="changeMobileNumber"
            @validate="populateNumberObject"
            class="margins__mobile-md-double"
            :data-vv-as="$t('FORM.VALIDATOR.MOBILE')"
          />
        </b-col>
      </b-row>

      <b-row class="margins__double">
        <b-col md="6">
          <Select
            name="maritalStatus"
            :label="`${$t('FORM.MARITAL_STATUS')} *`"
            v-model.trim="formData.maritalStatus"
            :options="maritalStatusOptions"
            v-validate="{ required: true }"
            :error="errors.first('maritalStatus')"
            :data-vv-as="$t('FORM.VALIDATOR.MARITAL_STATUS')"
          />
        </b-col>
        <b-col md="6">
          <Select
            name="idDocumentType"
            :label="`${$t('FORM.ID_DOCUMENT_TYPE')} *`"
            v-model.trim="formData.idDocumentType"
            :options="idDocumentTypeOptions"
            v-validate="{ required: true }"
            class="margins__mobile-md-double"
            :error="errors.first('idDocumentType')"
            :data-vv-as="$t('FORM.VALIDATOR.ID_DOCUMENT_TYPE')"
          />
        </b-col>
      </b-row>

      <Check
        class="margins__double"
        id="pep-checkbox"
        name="customerIsPEP"
        v-model="formData.customerIsPEP"
      >
        {{ $t('FORM.CONFIRM_PERSON_IS_A_PEP') }}
        <Button variant="ghost" width="auto" v-b-toggle="`pep-help`">
          <HelpSVG class="icon" />
        </Button>
      </Check>
      <b-collapse id="pep-help" role="tabpanel">
        <Card color="grey" padding="medium" class="text-small">
          <p v-html="$t('FORM.PEP_CONFIRMATION_HELP_LT')" />
        </Card>
      </b-collapse>

      <Separator hasLine size="large" />

      <p
        class="text-small text-center"
        v-html="$t('FORM.PRIVACY_CONSENT', privacyPolicyConsent)"
      />

      <p class="text-small text-center">
        <i18n path="FORM.ALLOW_REGISTRY_CHECK_LT" :tag="false">
          <span class="text-link" v-b-toggle="`registry-help`">
            {{ $t('FORM.ALLOW_REGISTRY_CHECK_LT_BUTTON') }}
          </span>
        </i18n>
      </p>
      <b-collapse id="registry-help" role="tabpanel">
        <Card color="grey" padding="medium" class="text-small">
          <p v-html="$t('FORM.ALLOW_REGISTRY_CHECK_LT_HELP')" />
        </Card>
      </b-collapse>

      <p
        class="text-small text-center"
        v-html="
          $t(
            'FORM.AGREE_TO_PERSONAL_DATA_PROCESSING',
            personalDataProcessingConsent,
          )
        "
      />

      <div class="text-center margins__triple">
        <Button type="submit">{{ $t('CONTINUE') }}</Button>
      </div>
    </Margins>
  </form>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import { SET_LOAN_APPLICATION_DATA } from '@/store/types';
import { STORE } from '@/constants';
import {
  Button,
  Check,
  Input,
  Margins,
  Separator,
  Select,
  MobileInput,
  Card,
} from '@/components';
import { constants } from '@/mixins';
import HelpSVG from '@/assets/images/question-circle-solid.svg';

export default {
  name: 'LoanApplicationFormLT',
  mixins: [constants],
  components: {
    Input,
    Margins,
    Button,
    Check,
    Separator,
    Select,
    MobileInput,
    Card,
    HelpSVG,
  },
  data() {
    return {
      formData: {
        country: STORE.LT.country.toUpperCase(),
        employment: {
          fieldOfActivity: null,
        },
        incomes: [
          {
            declaredNetMonthlyAmount: '',
          },
        ],
        liabilities: [
          {
            declaredMonthlyPayment: '',
          },
        ],
        agreeToPersonalDataProcessing: true,
        allowRegistryCheck: true,
        customerIsPEP: false,
      },
      numberObject: {
        number: {
          input: '',
          international: '',
        },
      },
      numberData: null,
    };
  },
  mounted() {
    this.formData = {
      ...this.formData,
      ...this.removeConsents(this.loanApplicationData),
    };
    this.numberObject.number.international = this.loanApplicationData?.mobile;
  },
  methods: {
    ...mapMutations([SET_LOAN_APPLICATION_DATA]),
    removeConsents(object) {
      if (!object) {
        return this.formData;
      }

      const { customerIsNotPEP, ...formData } = object; // eslint-disable-line no-unused-vars
      return formData;
    },
    async handleSubmit(e) {
      e.preventDefault();
      this.formData.language = this.language;

      if (this.formData.honeypot) {
        return false;
      }

      const valid = await this.$validator.validateAll();
      if (!valid) {
        return false;
      }

      this.formData.mainMobileNo =
        this.numberObject.number.international.replace(/\s/g, '');

      const clonedFormData = JSON.parse(JSON.stringify(this.formData));
      clonedFormData.bundleId = this.bundle.id;
      clonedFormData.customerIsPEP = !!clonedFormData.customerIsPEP;

      const data = {
        ...clonedFormData,
      };

      this.SET_LOAN_APPLICATION_DATA(data);

      this.$router.push({ name: this.ROUTES.LOAN_APPLICATION_LOADING.name });

      this.formData = { employment: {} };
      this.$validator.reset();
    },
    changeMobileNumber(numberObject) {
      const international =
        numberObject.number?.international || numberObject.number.input;
      this.numberObject = {
        ...this.numberObject,
        number: {
          ...this.numberObject.number,
          international,
        },
      };
    },
    populateNumberObject(numberObject) {
      if (!this.numberObject.input) {
        this.numberObject = numberObject;
      }
    },
  },
  computed: {
    ...mapState(['loanApplicationData', 'language']),
    ...mapGetters(['bundle']),
    privacyPolicyConsent() {
      const termsAndConditionsFileName = `TermsAndConditions-LT-${this.$i18n.locale.toUpperCase()}`;
      const policyFileName = `PrivacyPolicy${this.$i18n.locale.toUpperCase()}`;
      return [
        `<a href="${window.location.origin}/documents/${termsAndConditionsFileName}.pdf" target="_blank" rel="noopener noreferrer">`,
        `</a>`,
        `<a href="${window.location.origin}/documents/${policyFileName}.pdf" target="_blank" rel="noopener noreferrer">`,
        `</a>`,
      ];
    },
    personalDataProcessingConsent() {
      const dataProccessingFileName = `BigbankDataProccessingLT`;
      return [
        `<a href="${window.location.origin}/documents/${dataProccessingFileName}.pdf" target="_blank" rel="noopener noreferrer">`,
        `</a>`,
      ];
    },
    maritalStatusOptions() {
      return [
        { value: 'SINGLE', label: this.$t('MARITAL_STATUS.SINGLE') },
        { value: 'MARRIED', label: this.$t('MARITAL_STATUS.MARRIED') },
        { value: 'DIVORCED', label: this.$t('MARITAL_STATUS.DIVORCED') },
        { value: 'WIDOW', label: this.$t('MARITAL_STATUS.WIDOW') },
      ];
    },
    idDocumentTypeOptions() {
      return [
        {
          value: 'PASSPORT',
          label: this.$t('ID_DOCUMENT_TYPE.PASSPORT'),
        },
        {
          value: 'ID_CARD',
          label: this.$t('ID_DOCUMENT_TYPE.ID_CARD'),
        },
        {
          value: 'PERMANENT_RESIDENCE_PERMIT_CARD',
          label: this.$t('ID_DOCUMENT_TYPE.PERMANENT_RESIDENCE_PERMIT_CARD'),
        },
        {
          value: 'EU_RESIDENCE_PERMIT_CARD',
          label: this.$t('ID_DOCUMENT_TYPE.EU_RESIDENCE_PERMIT_CARD'),
        },
      ];
    },
  },
};
</script>
