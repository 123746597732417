<template>
  <div
    :class="[
      'card',
      {
        [`card--padding-${padding}`]: !!padding,
        [`card--color-${color}`]: !!color,
        [`card--variant-${variant}`]: !!variant,
        [`card--overflow-${overflow}`]: !!overflow,
        [`card--corner-${corner}`]: !!corner,
      },
    ]"
  >
    <div class="card__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    padding: {
      validator: function (value) {
        return ['small', 'medium', 'large'].includes(value);
      },
    },
    color: {
      validator: function (value) {
        return ['grey'].includes(value);
      },
    },
    variant: {
      validator: function (value) {
        return ['border'].includes(value);
      },
    },
    corner: {
      validator: function (value) {
        return ['none'].includes(value);
      },
    },
    overflow: {
      validator: function (value) {
        return ['hidden'].includes(value);
      },
    },
  },
};
</script>

<style lang="scss">
.card {
  background: $color-white;
  border-radius: $border-radius;
  border: rem(1px) solid transparent;
}

.card--overflow-hidden {
  overflow: hidden;
}

.card--padding-small {
  padding: 1rem;
}

.card--padding-medium {
  padding: 1rem;

  @include min-width(md) {
    padding: 2rem;
  }
}

.card--padding-large {
  padding: 1rem;

  @include min-width(md) {
    padding: 3rem;
  }
}

.card--color-grey {
  background: $color-grey-light;
}

.card--variant-border {
  border-color: $color-grey-border;
}

.card--corner-none {
  border-radius: 0;
}

.card__image {
  .card--padding-small & {
    margin: -1rem -1rem 0;
  }

  .card--padding-medium & {
    margin: -1rem -1rem 0;

    @include min-width(md) {
      margin: -2rem -2rem 0;
    }
  }

  .card--padding-large & {
    margin: -1rem -1rem 0;

    @include min-width(md) {
      margin: -3rem -3rem 0;
    }
  }
}
</style>
