<template>
  <footer class="footer">
    <Container class="text-extra-small text-grey">
      <b-row v-if="isAuthenticated">
        <b-col sm class="text-center">
          <Margins class="margins__mobile-sm footer__left">
            <p class="text-strong">
              {{ $t('FOOTER.FAIROWN_SUPPORT') }}
            </p>
            <ul class="footer__list">
              <li class="footer__list-item">
                <a
                  class="footer__list-item"
                  :href="`mailto:${$t('FOOTER.FAIROWN_SUPPORT_EMAIL')}`"
                >
                  {{ $t('FOOTER.FAIROWN_SUPPORT_EMAIL') }}
                </a>
              </li>
            </ul>
          </Margins>
        </b-col>
        <b-col sm class="text-center">
          <Margins class="margins__mobile-sm">
            <p v-if="isInbankEnabled" class="text-strong">
              {{ $t('FOOTER.INBANK_SUPPORT') }}
            </p>
            <p v-else class="text-strong">{{ $t('FOOTER.BIGBANK_SUPPORT') }}</p>
            <ul class="footer__list">
              <li class="footer__list-item">
                <span>
                  {{ $t(`COUNTRIES.${STORE.PL.country.toUpperCase()}`) }}:
                </span>
                <a
                  v-if="isInbankEnabled"
                  :href="`tel:${$t(
                    `FOOTER.INBANK_SUPPORT_NUMBER_${STORE.PL.country.toUpperCase()}`,
                  ).replace(/\s+/g, '')}`"
                >
                  {{
                    $t(
                      `FOOTER.INBANK_SUPPORT_NUMBER_${STORE.PL.country.toUpperCase()}`,
                    )
                  }}
                </a>
              </li>
            </ul>
          </Margins>
        </b-col>

        <b-col sm class="text-center">
          <Margins class="margins__mobile-sm footer__right">
            <p class="text-strong">{{ $t('FOOTER.DOCUMENTS') }}</p>
            <ul class="footer__list">
              <li class="footer__list-item">
                <a
                  href="/documents/Polityka_prywatnosci.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  {{ $t('FOOTER.PRIVACY_POLICY') }}
                </a>
              </li>
              <li class="footer__list-item">
                <a
                  href="/documents/SelfStudyGuide.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  {{ $t('FOOTER.SELF_STUDY_GUIDE') }}
                </a>
              </li>
            </ul>
          </Margins>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col md="8">
          <p>{{ $t('FOOTER.DESCRIPTION') }}</p>
        </b-col>
        <b-col>
          <Margins size="05x" class="footer__right margins__mobile-sm">
            <p>
              {{ $t('FOOTER.CONTACT') }}
            </p>
            <ul class="footer__list">
              <li class="footer__list-item">
                <a :href="`${$t('FOOTER.WEBPAGE')}`">
                  {{ $t('FOOTER.WEBPAGE') }}
                </a>
              </li>
              <li class="footer__list-item">
                <a :href="`tel:${$t('FOOTER.PHONE').replace(/\s/g, '')}`">
                  {{ $t('FOOTER.PHONE') }}
                </a>
              </li>
            </ul>
          </Margins>
        </b-col>
      </b-row>
    </Container>
  </footer>
</template>

<script>
import { Container, Margins } from '@/components';
import { mapGetters } from 'vuex';
import { constants } from '@/mixins';
import { INBANK_ENABLED } from '@/constants';

export default {
  name: 'Footer',
  mixins: [constants],
  components: {
    Container,
    Margins,
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    isInbankEnabled() {
      return INBANK_ENABLED;
    },
  },
};
</script>

<style lang="scss">
.footer {
  background: $color-grey-light;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.footer__list-item + .footer__list-item {
  margin-top: 0.25rem;
}

.footer__right {
  @include min-width(md) {
    text-align: right;
  }
}
.footer__left {
  @include min-width(md) {
    text-align: left;
  }
}
</style>
