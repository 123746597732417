<template>
  <form
    @submit="handleSubmit"
    class="loan-application-form"
    autocomplete="off"
    :class="{ margins__triple: !isAuthenticated }"
  >
    <Input
      name="bot-field"
      label="Don’t fill this out if you're human"
      v-model="formData.honeypot"
      autocomplete="random"
      isHoneyPot
    />
    <Margins>
      <b-row>
        <b-col md="6">
          <Input
            :label="`${$t('FORM.FIRST_NAME')} *`"
            name="firstName"
            autocomplete="given-name"
            v-model.trim="formData.customerData.firstName"
            :error="errors.first('firstName')"
            v-validate="{
              required: true,
              regex: REGEX.INBANK.PL.name,
            }"
            :data-vv-as="$t('FORM.VALIDATOR.FIRST_NAME')"
          />
        </b-col>
        <b-col md="6">
          <Input
            :label="`${$t('FORM.LAST_NAME')} *`"
            name="surname"
            autocomplete="family-name"
            v-model.trim="formData.customerData.lastName"
            :error="errors.first('surname')"
            v-validate="{
              required: true,
              regex: REGEX.INBANK.PL.name,
            }"
            class="margins__mobile-md-double"
            :data-vv-as="$t('FORM.VALIDATOR.LAST_NAME')"
          />
        </b-col>
      </b-row>

      <Input
        :label="`${$t('FORM.ID_NUMBER')} *`"
        name="personalIdentityCode"
        placeholder="00000000000"
        v-model.trim="formData.customerData.identityCode"
        :error="errors.first('personalIdentityCode')"
        v-validate="'required|personalCodePL'"
        class="margins__double"
        :data-vv-as="$t('FORM.VALIDATOR.ID_NUMBER')"
      />

      <b-row class="margins__double">
        <b-col md="6">
          <Input
            type="email"
            :label="`${$t('FORM.EMAIL')} *`"
            name="email"
            autocomplete="email"
            v-model.trim="formData.customerContactData.email"
            :error="errors.first('email')"
            v-validate="{ required: true, email: true }"
            :data-vv-as="$t('FORM.VALIDATOR.EMAIL')"
          />
        </b-col>
        <b-col md="6">
          <MobileInput
            ref="mobileInput"
            :default-country="STORE.PL.country.toUpperCase()"
            mode="international"
            name="mainMobileNo"
            :label="`${$t('FORM.MOBILE')} *`"
            dynamic-placeholder
            :onlyCountries="[STORE.PL.country.toUpperCase()]"
            valid-characters-only
            disabled-fetching-country
            :value="numberObject.number.international"
            :error="errors.first('mainMobileNo')"
            v-validate="'required|inbankPhonePL'"
            @input="changeMobileNumber"
            @validate="populateNumberObject"
            class="margins__mobile-md-double"
            :data-vv-as="$t('FORM.VALIDATOR.MOBILE')"
          />
        </b-col>
      </b-row>

      <Separator hasLine size="large" />

      <Check
        :label="$t('FORM.SELECT_ALL')"
        name="selectAll"
        v-model="formData.selectAll"
        @input="toggleSelectAll"
      />

      <Check
        :label="privacyPolicyConsent"
        name="privacyConsent"
        v-model="formData.privacyConsent"
        v-validate="{ required: true }"
      />

      <Check
        :label="$t('FORM.PERSONAL_INFO_CONSENT')"
        name="personalInfoConsent"
        v-model="formData.personalInfoConsent"
        v-validate="{ required: true }"
      />

      <Check
        :label="$t('FORM.CONTRACT_CONSENT')"
        name="contractConsent"
        v-model="formData.contractConsent"
        v-validate="{ required: true }"
      />

      <div
        v-if="
          errors.first('privacyConsent') ||
          errors.first('personalInfoConsent') ||
          errors.first('contractConsent')
        "
        class="check__error"
      >
        {{ $t('FORM.VALIDATOR.TERMS') }}
      </div>

      <p class="text-small text-center margins__triple">
        {{ $t('FORM.CREDIT_CHECK_INFO') }}
      </p>

      <div class="text-center">
        <Button type="submit">{{ $t('CONTINUE') }}</Button>
      </div>

      <p class="text-small text-center">
        {{ $t('FORM.NO_COMMITMENT_WITH_APPLICATION') }}
      </p>
    </Margins>
  </form>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import { SET_LOAN_APPLICATION_DATA } from '@/store/types';
import {
  Button,
  Check,
  Input,
  Margins,
  Separator,
  MobileInput,
} from '@/components';
import { constants } from '@/mixins';
import { STORE } from '@/constants';

export default {
  name: 'InbankLoanApplicationFormPL',
  mixins: [constants],
  components: {
    Input,
    Margins,
    Button,
    Check,
    Separator,
    MobileInput,
  },
  data() {
    return {
      formData: {
        country: STORE.PL.country.toUpperCase(),
        locale: 'pl-PL',
        customerData: {},
        customerContactData: {},
        purchase: {},
      },
      numberObject: {
        number: {
          input: '',
          international: '',
        },
      },
    };
  },
  mounted() {
    this.formData = this.setLoanApplicationData(this.loanApplicationData);
    this.numberObject.number.international = this.loanApplicationData?.mobile;
  },
  methods: {
    ...mapMutations([SET_LOAN_APPLICATION_DATA]),
    setLoanApplicationData(object) {
      if (!object) {
        return this.formData;
      }
      this.formData.customerData.firstName = object.firstName;
      this.formData.customerData.lastName = object.surname;
      this.formData.customerContactData.email = object.email;
      this.formData.purchase.purchaseReference = object.referenceNumber;
      return this.formData;
    },
    async handleSubmit(e) {
      e.preventDefault();
      this.formData.language = this.language;

      if (this.formData.honeypot) {
        return false;
      }

      const valid = await this.$validator.validateAll();
      if (!valid) {
        return false;
      }

      this.formData.customerContactData.mobile =
        this.numberObject.number.international.replace(/\s/g, '');

      const clonedFormData = JSON.parse(JSON.stringify(this.formData));
      clonedFormData.bundleId = this.bundle.id;
      clonedFormData.personalInfoConsent = !!clonedFormData.personalInfoConsent;
      clonedFormData.privacyConsent = !!clonedFormData.privacyConsent;
      clonedFormData.contractConsent = !!clonedFormData.contractConsent;

      const data = {
        ...clonedFormData,
        termsAndConditionsConsent: clonedFormData.privacyConsent,
        language: this.language,
      };

      this.SET_LOAN_APPLICATION_DATA(data);

      this.$router.push({ name: this.ROUTES.LOAN_APPLICATION_LOADING.name });

      this.formData = { customerData: {}, customerContactData: {} };
      this.$validator.reset();
    },
    changeMobileNumber(numberObject) {
      const international =
        numberObject.number?.international || numberObject.number.input;
      this.numberObject = {
        ...this.numberObject,
        number: {
          ...this.numberObject.number,
          international,
        },
      };
    },
    populateNumberObject(numberObject) {
      if (!this.numberObject.input) {
        this.numberObject = numberObject;
      }
    },
    toggleSelectAll(toggled) {
      this.formData.privacyConsent = toggled;
      this.formData.personalInfoConsent = toggled;
      this.formData.contractConsent = toggled;
    },
  },
  computed: {
    ...mapState(['loanApplicationData', 'language', 'backOffice']),
    ...mapGetters(['bundle']),
    privacyPolicyConsent() {
      const termsAndConditionsFileName = `TermsAndConditions-PL-${this.$i18n.locale.toUpperCase()}`;
      const policyFileName = `PrivacyPolicyPL-${this.$i18n.locale.toUpperCase()}`;
      return `<p>${this.$t('FORM.PRIVACY_CONSENT', [
        `<a href="${window.location.origin}/documents/${termsAndConditionsFileName}.pdf" target="_blank" rel="noopener noreferrer">`,
        `</a>`,
        `<a href="${window.location.origin}/documents/${policyFileName}.pdf" target="_blank" rel="noopener noreferrer">`,
        `</a>`,
      ])}</p>`;
    },
    isAuthenticated() {
      return Boolean(this.backOffice.authenticatedUser);
    },
  },
};
</script>
