import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

import * as types from './types';
import ApiService from '../api';
import {
  DECISION_TYPES,
  INBANK_ENABLED,
  LOCALES,
  PERSISTED_STORE_KEY,
  ROUTES,
  STEPS,
  STORE,
} from '@/constants';
import router from '../router';
import i18n from '../i18n';
import { getPersistedStore } from '@/utils';
import backOffice from './backOffice';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: PERSISTED_STORE_KEY,
  storage: window.localStorage,
  // Function that passes the state and returns the state with only the objects you want to store.
  reducer: (state) => ({
    language: state.language,
    storeOptions: state.storeOptions,
    currentStep: state.currentStep,
    selectedBundle: state.selectedBundle,
    loanApplicationDecision: state.loanApplicationDecision,
    currentStore: state.currentStore,
    productCode: state.productCode,
    countryCodes: state.countryCodes,
    backOffice: {
      loggedIn: state.backOffice.loggedIn,
      authenticatedUser: state.backOffice.authenticatedUser,
    },
    loanApplicationData: state.loanApplicationData,
  }),
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

const persistedStore = getPersistedStore();
// Polish store is very likely to get status PENDING for 5+ minutes so not letting users always wait long time.
const POLISH_RETRY_ATTEMPTS = 4;
const OTHER_RETRY_ATTEMPTS = 10;

export default new Vuex.Store({
  plugins: [vuexLocalStorage.plugin],
  modules: { backOffice },
  state: {
    language: persistedStore.language || i18n.locale,
    storeOptions: persistedStore.storeOptions || STORE.PL,
    currentStep: persistedStore.currentStep || STEPS.select,
    selectedBundle: persistedStore.selectedBundle || null,
    loanApplicationData: persistedStore.loanApplicationData || null,
    isLoading: false,
    hasError: false,
    notification: null,
    hasLoanApplicationError: false,
    loanApplicationErrorMessage: '',
    applicationStatus: null,
    loanApplicationDecision: null,
    currentStore: persistedStore.currentStore || null,
    productCode: persistedStore.productCode || null,
    countryCodes: persistedStore.countryCodes || [],
    pickUpStores: [],
    omnivaMachines: [],
  },
  actions: {
    [types.REDIRECT_TO_MERCHANT_WITH_TIMEOUT](
      { dispatch },
      isRejected = false,
    ) {
      setTimeout(() => {
        dispatch(types.REDIRECT_TO_MERCHANT, isRejected);
      }, 5 * 1000);
    },
    [types.REDIRECT_TO_MERCHANT]({ state }, isRejected = false) {
      if (state.backOffice.authenticatedUser) {
        router.push({ name: ROUTES.BACK_OFFICE.name });
      } else if (isRejected === true && state.loanApplicationDecision.failUrl) {
        window.location = state.loanApplicationDecision.failUrl;
      } else if (state.loanApplicationDecision.redirectUrl) {
        window.location = state.loanApplicationDecision.redirectUrl;
      }
    },
    async [types.SUBMIT_LOAN_APPLICATION]({ commit, dispatch, state }) {
      try {
        commit(types.IS_LOADING, true);

        let response;
        const counter = 1;
        switch (state.loanApplicationData.country.toUpperCase()) {
          case STORE.EE.country.toUpperCase():
            if (INBANK_ENABLED) {
              response = await ApiService.submitInbankLoanApplication(
                state.loanApplicationData,
              );
              break;
            } else {
              response = await ApiService.submitBigbankLoanApplicationEE(
                state.loanApplicationData,
              );
              break;
            }
          case STORE.PL.country.toUpperCase():
            if (INBANK_ENABLED) {
              response = await ApiService.submitInbankLoanApplication(
                state.loanApplicationData,
              );
              break;
            }
        }

        commit(types.SET_LOAN_APPLICATION_DECISION, response);
        dispatch(types.HANDLE_APPLICATION_RESPONSE, response);
      } catch (error) {
        if (error.statusCode === 400) {
          commit(types.LOAN_APPLICATION_ERROR_MESSAGE, error);
        }
        commit(types.HAS_LOAN_APPLICATION_ERROR, error);
      } finally {
        commit(types.IS_LOADING, false);
      }
    },
    [types.HANDLE_APPLICATION_RESPONSE]({ commit, state }, response) {
      const {
        SIGN_CONTRACT,
        CONTRACT_SIGNED,
        APPROVED,
        REJECTED,
        PROCESSING,
        ACTIVATED,
        PAYOUT,
        ANNULLED,
        ADDITIONAL_DATA_NEEDED,
        PENDING,
        GRANTED,
        COMPLETED,
        DECLINED,
        POST_RECEIVED,
        SIGNED_DEAL,
        SIGNED_DEAL_NO_ADVANCE,
        SIGNED_DEAL_YOUR_ADVANCE,
        SIGNED_DEAL_OFFLINE,
        CLIENT_CANCELED,
        NO_LOAN_POSSIBLE,
        INIT_AUTH,
        WAITING_FOR_PERMISSION,
        PERMISSION_SIGNED,
        VALIDATING_INFO,
        WAITING_FOR_SIF_CONFIRM,
        WAITING_FOR_DEAL_SIGN,
        NEED_HELP,
        ERROR,
      } = DECISION_TYPES;

      switch (response.status) {
        case CONTRACT_SIGNED:
        case SIGN_CONTRACT:
        case COMPLETED:
        case GRANTED:
        case SIGNED_DEAL:
        case SIGNED_DEAL_NO_ADVANCE:
        case SIGNED_DEAL_YOUR_ADVANCE:
        case SIGNED_DEAL_OFFLINE:
          commit(types.SET_CURRENT_STEP, STEPS.decision);
          router.push({ name: ROUTES.DECISION_SUCCESS.name });
          break;
        case POST_RECEIVED:
        case APPROVED:
        case ADDITIONAL_DATA_NEEDED:
        case INIT_AUTH:
        case WAITING_FOR_PERMISSION:
        case PERMISSION_SIGNED:
        case VALIDATING_INFO:
        case WAITING_FOR_SIF_CONFIRM:
        case WAITING_FOR_DEAL_SIGN:
        case NEED_HELP:
          if (state.currentStep < STEPS.clientRedirected) {
            commit(types.SET_CURRENT_STEP, STEPS.clientRedirected);
            window.location.href = response.continueLink;
          }
          break;
        case ERROR:
          if (response.failUrl) {
            window.location.href = response.failUrl;
          } else {
            router.push({ name: ROUTES.PAGE_404.name });
          }
          break;
        case REJECTED:
        case DECLINED:
        case NO_LOAN_POSSIBLE:
          commit(types.SET_CURRENT_STEP, STEPS.decision);
          router.push({ name: ROUTES.DECISION_DENIED.name });
          break;
        case ANNULLED:
        case CLIENT_CANCELED:
          commit(types.SET_CURRENT_STEP, STEPS.decision);
          router.push({ name: ROUTES.DECISION_CANCELLED.name });
          break;
        case PENDING:
          if (state.currentStep < STEPS.clientRedirected) {
            commit(types.SET_CURRENT_STEP, STEPS.clientRedirected);
            window.location.href = response.continueLink;
          } else {
            router.push({ name: ROUTES.DECISION_PENDING.name });
          }
          break;
        case PROCESSING:
        case ACTIVATED:
        case PAYOUT:
        default:
          router.push({
            name: ROUTES.HOME.name,
            params: { productCode: state.productCode },
          });
      }
    },
    async [types.POLL_APPLICATION_RESPONSE](
      { commit, dispatch },
      { response, counter },
    ) {
      const timeBetweenPollsMs = 5000;
      const numberOfTries = 15;
      try {
        if (counter >= numberOfTries) {
          commit(
            types.LOAN_APPLICATION_ERROR_MESSAGE,
            i18n.t('BANK_REQUEST_TIMED_OUT'),
          );
          commit(types.HAS_LOAN_APPLICATION_ERROR, error);
          return;
        }

        response = await ApiService.getContinueLink(response.referenceNumber);
        if (
          response.status !== DECISION_TYPES.PROCESSING &&
          response.status !== null &&
          response.continueLink
        ) {
          commit(types.SET_LOAN_APPLICATION_DECISION, response);
          dispatch(types.HANDLE_APPLICATION_RESPONSE, response);
        } else {
          counter++;
          setTimeout(() => {
            dispatch(types.POLL_APPLICATION_RESPONSE, { response, counter });
          }, timeBetweenPollsMs);
        }
      } catch (error) {
        commit(types.HAS_LOAN_APPLICATION_ERROR, error);
      } finally {
        commit(types.IS_LOADING, false);
      }
    },
    async [types.GET_APPLICATION_STATUS](
      { commit, dispatch, getters },
      referenceNumber,
    ) {
      try {
        commit(types.IS_LOADING, true);

        const response = await ApiService.getApplicationStatus(referenceNumber);
        commit(types.SET_LOAN_APPLICATION_DECISION, response);

        const numberOfRetries = getters.isStorePolish
          ? POLISH_RETRY_ATTEMPTS
          : OTHER_RETRY_ATTEMPTS;

        let retry = response.status === DECISION_TYPES.PENDING;
        let count = 1;
        while (retry) {
          const retryResponse = await ApiService.retryApiRequest(() =>
            ApiService.getApplicationStatus(referenceNumber),
          );
          if (retryResponse.status !== DECISION_TYPES.PENDING) {
            commit(types.SET_LOAN_APPLICATION_DECISION, retryResponse);
            retry = false;
          }

          if (count >= numberOfRetries) {
            retry = false;
          }
          count++;
        }
      } catch (error) {
        if (error.statusCode === 400) {
          router.push({ name: ROUTES.PAGE_404.name });
        }
        commit(types.HAS_LOAN_APPLICATION_ERROR, error);
      } finally {
        commit(types.IS_LOADING, false);
      }
    },
    async [types.GET_APPLICATION_STATUS_AND_REDIRECT](
      { state, dispatch },
      referenceNumber,
    ) {
      await dispatch(types.GET_APPLICATION_STATUS, referenceNumber);
      dispatch(
        types.HANDLE_APPLICATION_RESPONSE,
        state.loanApplicationDecision,
      );
    },
    async [types.GET_STORE_FROM_SHORT_NAME]({ commit, dispatch }, storeName) {
      try {
        const response = await ApiService.getStoreFromShortName(storeName);

        commit(types.SET_CURRENT_STORE, response);
        dispatch(types.SET_STORE_OPTIONS, response);
      } catch (error) {
        router.push({ name: ROUTES.PAGE_404.name });
      }
    },
    async [types.SET_STORE_OPTIONS]({ commit, state }, store) {
      const countryCode = store.country.toLowerCase();
      const isValidStore = [STORE.EE.country, STORE.PL.country].includes(
        countryCode,
      );
      const defaultCountryCode = STORE.PL.country.toUpperCase();
      const storeOptions =
        STORE[isValidStore ? countryCode.toUpperCase() : defaultCountryCode];

      if (countryCode === 'xx') {
        storeOptions.availableLocales = Object.values(LOCALES);
      }

      commit(types.SET_STORE_OPTIONS, storeOptions);

      if (!storeOptions.availableLocales.includes(state.language)) {
        i18n.locale = storeOptions.lang;
        commit(types.SET_LANGUAGE, storeOptions.lang);
      }
    },
    async [types.GET_BUNDLE_FROM_PRODUCT_CODE]({ commit }, productCode) {
      try {
        commit(types.IS_LOADING, true);

        const product = await ApiService.getBundleFromCountryCodeAndProductCode(
          this.state.storeOptions.country,
          productCode,
        );
        commit(types.SET_SELECTED_BUNDLE_AND_ASSET, product);
        commit(types.SET_BUNDLE_PRODUCT, productCode);
      } catch (error) {
        router.push({ name: ROUTES.PAGE_404.name });
      } finally {
        commit(types.IS_LOADING, false);
      }
    },
    async [types.GET_PICKUP_STORES]({ commit, state }) {
      try {
        if (state.pickUpStores.length === 0) {
          const stores = await ApiService.getPickupStores();
          commit(types.SET_PICKUP_STORES, stores);
        }
      } catch (error) {
        commit(types.HAS_ERROR, error);
        throw new Error(error);
      }
    },
    async [types.GET_OMNIVA_MACHINES]({ commit, state }) {
      try {
        if (state.omnivaMachines.length === 0) {
          const omnivaMachines = await ApiService.getOmnivaMachines();
          commit(types.SET_OMNIVA_MACHINES, omnivaMachines);
        }
      } catch (error) {
        commit(types.HAS_ERROR, error);
        throw new Error(error);
      }
    },
    async [types.SUBMIT_DELIVERY_INFO]({ commit, state }, data) {
      try {
        commit(types.IS_LOADING, true);
        await ApiService.submitDeliveryInfo(data);
      } catch (error) {
        commit(types.HAS_ERROR, error);
        throw new Error(error);
      } finally {
        commit(types.IS_LOADING, false);
      }
    },
    async [types.SET_BUNDLE_FROM_REFERENCE_NUMBER](
      { commit },
      referenceNumber,
    ) {
      try {
        commit(types.IS_LOADING, true);

        const response = await ApiService.getBundleOrderForIDeal(
          referenceNumber,
        );
        commit(types.SET_BUNDLE_PRODUCT, response.product.bundle.productCode);
        commit(types.SET_LOAN_APPLICATION_DATA, response.application);

        commit(types.SET_SELECTED_BUNDLE_AND_ASSET, response.product);
      } catch (error) {
        router.push({ name: ROUTES.PAGE_404.name });
      } finally {
        commit(types.IS_LOADING, false);
      }
    },
    async [types.GET_CITIZEN_COUNTRY_CODES]({ commit, state }) {
      try {
        if (state.countryCodes.length === 0) {
          const response = await ApiService.getCitizenCountryCodes();
          commit(types.SET_CITIZEN_COUNTRY_CODES, response);
        }
      } catch (error) {
        commit(types.HAS_ERROR, error);
      }
    },
  },
  getters: {
    asset: (state) => {
      return state.selectedBundle?.asset || {};
    },
    bundle: (state) => {
      return state.selectedBundle?.bundle || {};
    },
    isStorePolish: (state) => {
      return state.storeOptions.country === STORE.PL.country;
    },
    isBackofficeUserLoggedIn: (state) => {
      return state.backOffice.loggedIn;
    },
    canRedirectToMerchant: (state) => {
      return (
        state.backOffice.authenticatedUser ||
        state.loanApplicationDecision.failUrl ||
        state.loanApplicationDecision.redirectUrl
      );
    },
  },
  mutations: {
    [types.RESET_STORE](state, isHalfSweep) {
      state.currentStep = STEPS.select;
      state.selectedBundle = null;
      state.loanApplicationData = null;
      state.applicationStatus = null;
      state.loanApplicationData = null;
      state.loanApplicationDecision = isHalfSweep
        ? {
            referenceNumber: state.loanApplicationDecision.referenceNumber,
            redirectUrl: state.loanApplicationDecision.redirectUrl,
            failUrl: state.loanApplicationDecision.failUrl,
          }
        : null;
    },
    [types.SET_LANGUAGE](state, language) {
      state.language = language;
    },
    [types.SET_STORE_OPTIONS](state, storeOptions) {
      state.storeOptions = storeOptions;
    },
    [types.IS_LOADING](state, loading) {
      state.isLoading = loading;
    },
    [types.HAS_ERROR](state, error) {
      state.hasError = error;
    },
    [types.NOTIFICATION](state, notification) {
      state.notification = notification;
    },
    [types.SET_CURRENT_STEP](state, step) {
      state.currentStep = step;
    },
    [types.SET_SELECTED_BUNDLE_AND_ASSET](state, bundle) {
      state.selectedBundle = bundle;
    },
    [types.SET_LOAN_APPLICATION_DATA](state, data) {
      state.loanApplicationData = data;
    },
    [types.HAS_LOAN_APPLICATION_ERROR](state, error) {
      state.hasLoanApplicationError = error;
    },
    [types.LOAN_APPLICATION_ERROR_MESSAGE](state, errorMessage) {
      state.loanApplicationErrorMessage = errorMessage;
    },
    [types.SET_APPLICATION_STATUS](state, status) {
      state.applicationStatus = status;
    },
    [types.SET_LOAN_APPLICATION_DECISION](state, decision) {
      state.loanApplicationDecision = decision;
    },
    [types.SET_CURRENT_STORE](state, currentStore) {
      state.currentStore = currentStore;
    },
    [types.SET_BUNDLE_PRODUCT](state, product) {
      state.productCode = product;
    },
    [types.SET_PICKUP_STORES](state, stores) {
      state.pickUpStores = stores;
    },
    [types.SET_OMNIVA_MACHINES](state, omnivaMachines) {
      state.omnivaMachines = omnivaMachines;
    },
    [types.SET_CITIZEN_COUNTRY_CODES](state, countryCodes) {
      state.countryCodes = countryCodes;
    },
  },
});
