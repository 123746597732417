<template>
  <div>
    <Card padding="small" variant="border" corner="none">
      <Margins>
        <form
          @submit.prevent="querySearch"
          data-vv-scope="form1"
          autocomplete="off"
        >
          <Margins size="05x">
            <b-row align-v="end" align-h="end">
              <b-col md="8">
                <MultiSelect
                  v-model="statusFilter"
                  id="statusFilter"
                  name="statusFilter"
                  :options="filterStatusOptions"
                  :multiple="true"
                  :close-on-select="false"
                  :placeholder="$t('FILTER_BY_STATUS')"
                  :label="$t('FILTER_BY_STATUS')"
                  track-by="value"
                  :no-result="$t('NOT_FOUND')"
                  :no-options="$t('OPTIONS_EMPTY')"
                >
                  <template #icon>
                    <Button
                      variant="text"
                      clickable
                      @click="showModal"
                      class="text-left p-0"
                    >
                      <QuestionCircleSolidSVG class="icon" />
                    </Button>
                  </template>
                </MultiSelect>
              </b-col>
              <b-col md="4">
                <v-date-picker
                  ref="calendar"
                  is-range
                  :masks="{ input: ['DD.MM.YYYY'] }"
                  v-model="range"
                  :model-config="modelConfig"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <b-row>
                      <b-col sm="5" class="pr-md-0">
                        <Input
                          name="startDateFilter"
                          id="startDateFilter"
                          size="small"
                          :value="inputValue.start"
                          :inputListeners="inputEvents.start"
                          :label="$t('FILTER_BY_START_DATE')"
                          :placeholder="$t('FILTER_BY_START_DATE')"
                        />
                      </b-col>
                      <b-col sm="2" class="text-center pt-4 mx-n2">—</b-col>
                      <b-col sm="5" class="px-md-0">
                        <Input
                          name="endDateFilter"
                          id="endDateFilter"
                          class="pr-0"
                          size="small"
                          :value="inputValue.end"
                          :inputListeners="inputEvents.end"
                          :label="$t('FILTER_BY_END_DATE')"
                          :placeholder="$t('FILTER_BY_END_DATE')"
                        />
                      </b-col>
                    </b-row>
                  </template>
                </v-date-picker>
              </b-col>
            </b-row>

            <b-collapse v-model="filterVisible">
              <Margins size="05x">
                <b-row v-if="isAdmin">
                  <b-col>
                    <Input
                      v-model="referenceNumberFilter"
                      id="referenceNumberFilter"
                      name="referenceNumberFilter"
                      type="text"
                      size="small"
                      :placeholder="$t('FILTER_BY_REFERENCE_NUMBER')"
                      :label="$t('FILTER_BY_REFERENCE_NUMBER')"
                      v-validate="'min:3'"
                      :data-vv-as="$t('FILTER_BY_REFERENCE_NUMBER')"
                      :error="errors.first('form1.referenceNumberFilter')"
                    />
                  </b-col>
                  <b-col>
                    <Input
                      v-model="idealReferenceFilter"
                      id="idealReferenceFilter"
                      name="idealReferenceFilter"
                      type="text"
                      size="small"
                      :placeholder="$t('FILTER_BY_MERCHANT_REFERENCE')"
                      :label="$t('FILTER_BY_MERCHANT_REFERENCE')"
                      v-validate="'min:3'"
                      :data-vv-as="$t('FILTER_BY_MERCHANT_REFERENCE')"
                      :error="errors.first('form1.idealReferenceFilter')"
                    />
                  </b-col>
                </b-row>
              </Margins>
            </b-collapse>

            <b-row align-h="center" class="margins__one">
              <b-col md="4" class="margins__mobile-sm">
                <Button type="submit" width="auto" block size="extra-small">
                  {{ $t('FILTER') }}
                </Button>
              </b-col>
            </b-row>
          </Margins>
        </form>

        <template v-if="filterVisible">
          <Separator hasLine size="small" />

          <form
            @submit.prevent="querySearchCustomer"
            data-vv-scope="form2"
            autocomplete="off"
          >
            <b-row align-v="start">
              <b-col md="4" class="margins__mobile-sm">
                <Input
                  v-model="customerNameFilter"
                  name="customerNameFilter"
                  type="text"
                  size="small"
                  :placeholder="$t('FORM.CUSTOMER_NAME')"
                  id="customerNameFilter"
                  :label="$t('FORM.CUSTOMER_NAME')"
                  labelHidden
                  v-validate="'required|min:3'"
                  :data-vv-as="$t('FORM.CUSTOMER_NAME')"
                  :error="errors.first('form2.customerNameFilter')"
                />
              </b-col>
              <b-col md="4" class="margins__mobile-sm">
                <Button
                  type="submit"
                  width="auto"
                  block
                  size="extra-small"
                  variant="inverse"
                >
                  {{ $t('SEARCH_BY_NAME') }}
                </Button>
              </b-col>
            </b-row>
          </form>
        </template>
      </Margins>
      <StatusInformationModal
        v-model="isStatusInfoModalVisible"
        @change="changeModalVisibility"
      />
    </Card>

    <Button
      variant="ghost"
      size="small"
      block
      :class="filterVisible ? null : 'collapsed'"
      :aria-expanded="filterVisible ? 'true' : 'false'"
      aria-controls="filter"
      @click="handleFilterVisibility"
    >
      {{ filterVisible ? $t('CLOSE_FILTER') : $t('OPEN_FILTER') }}
      <template v-slot:icon>
        <ChevronDownSVG
          :class="['icon', { ['icon--rotated']: filterVisible }]"
        />
      </template>
      <template v-slot:icon-right>
        <ChevronDownSVG
          :class="['icon', { ['icon--rotated']: filterVisible }]"
        />
      </template>
    </Button>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  GET_BUNDLE_ORDER_CREATORS,
  GET_BUNDLE_ORDERS,
  GET_PICKUP_STORES,
} from '@/store/types';
import {
  Button,
  Card,
  Input,
  Margins,
  MultiSelect,
  Separator,
} from '@/components';
import { constants } from '@/mixins';
import {
  arrayFromString,
  filterByOptionValue,
  mapToOptionValue,
  orderStatusVariant,
} from '@/utils';
import ChevronDownSVG from '@/assets/images/chevron-down-solid.svg';
import QuestionCircleSolidSVG from '@/assets/images/question-circle-solid.svg';
import StatusInformationModal from '@/containers/BackOffice/StatusInformationModal.vue';

export default {
  name: 'BundleOrdersFilter',
  mixins: [constants],
  components: {
    StatusInformationModal,
    Button,
    Margins,
    MultiSelect,
    ChevronDownSVG,
    QuestionCircleSolidSVG,
    Separator,
    Input,
    Card,
  },
  props: {
    paginateTo: [Object],
  },
  data() {
    const filterVisible = JSON.parse(
      localStorage.getItem('idream-bo-filter-visible'),
    );
    return {
      statusFilter: [],
      creatorFilter: [],
      countryFilter: [],
      storeFilter: [],
      idealReferenceFilter: '',
      customerNameFilter: '',
      referenceNumberFilter: '',
      pageSize: 20,
      filtersChanged: false,
      filterVisible: filterVisible === null ? false : filterVisible,
      range: {
        start: '',
        end: '',
      },
      modelConfig: {
        type: 'string',
        mask: "YYYY-MM-DD'T'HH:MM:ssZZZZ",
      },
      isStatusInfoModalVisible: false,
    };
  },
  created() {
    if (this.isAdmin) {
      this.GET_BUNDLE_ORDER_CREATORS();
      this.GET_PICKUP_STORES();
    }
  },
  async mounted() {
    const shouldSearchFromQuery = Object.keys(this.$route.query).some((key) =>
      [
        'statuses',
        'creators',
        'countries',
        'stores',
        'idealReference',
        'referenceNumber',
        'pageSlice',
        'size',
        'createdDtime',
        'id',
        'startDate',
        'endDate',
      ].includes(key),
    );

    if (shouldSearchFromQuery) {
      const filters = {
        pageSize: this.$route.query.size,
        statuses: arrayFromString(this.$route.query.statuses),
        idealReference: this.$route.query.idealReference,
        referenceNumber: this.$route.query.referenceNumber,
        startDate: this.$route.query.startDate,
        endDate: this.$route.query.endDate,
      };
      const adminFilters = {
        ...(this.isAdmin && {
          creators: arrayFromString(this.$route.query.creators),
          countries: arrayFromString(this.$route.query.countries),
          stores: arrayFromString(this.$route.query.stores),
        }),
      };

      await this.initFilters({
        ...filters,
        ...adminFilters,
      });
      this.findOrders();
    } else {
      const defaultStatusFilterValues = [
        this.ORDER_STATUSES.COMPLETED,
        this.ORDER_STATUSES.GRANTED,
        this.ORDER_STATUSES.HANDED_OVER,
      ];

      await this.initFilters({
        pageSize: this.pageSize,
        statuses: defaultStatusFilterValues,
      });
      this.updateQueryFilter();
    }
  },
  watch: {
    paginateTo(val) {
      this.updateQueryFilter(val);
    },
    filters() {
      this.filtersChanged = true;
    },
    $route() {
      this.querySearch();
    },
    async pickUpStores() {
      const stores = arrayFromString(this.$route.query.stores);

      if (stores) {
        this.storeFilter = filterByOptionValue(this.filterStoreOptions, stores);
      }
    },
    async 'backOffice.creators'() {
      const creators = arrayFromString(this.$route.query.creators);

      if (creators) {
        this.creatorFilter = filterByOptionValue(
          this.filterCreatedByOptions,
          creators,
        );
      }
    },
  },
  methods: {
    ...mapActions([
      GET_BUNDLE_ORDERS,
      GET_BUNDLE_ORDER_CREATORS,
      GET_PICKUP_STORES,
    ]),
    initFilters(props) {
      return new Promise((resolve) => {
        this.pageSize = this.initPageSize(props.pageSize);
        const dateRange = {
          start: new Date(props.startDate) || null,
          end: new Date(props.endDate) || null,
        };
        this.$refs.calendar.updateValue(dateRange);
        this.statusFilter = filterByOptionValue(
          this.filterStatusOptions,
          props.statuses,
        );
        this.creatorFilter = filterByOptionValue(
          this.filterCreatedByOptions,
          props.creators,
        );
        this.countryFilter = filterByOptionValue(
          this.filterCountryOptions,
          props.countries,
        );
        this.storeFilter = filterByOptionValue(
          this.filterStoreOptions,
          props.stores,
        );
        this.idealReferenceFilter = props.idealReference || '';
        this.referenceNumberFilter = props.referenceNumber || '';
        resolve();
      });
    },
    initPageSize(value) {
      return this.pageSizeOptions.find((option) => option === +value);
    },
    updateQueryFilter(val = {}) {
      const query = Object.fromEntries(
        Object.entries(this.filters)
          .filter(([_, values]) => values.length)
          .map(([key, values]) => [
            [key],
            Array.isArray(values) ? values.join(',') : values,
          ]),
      );

      this.filtersChanged = false;
      this.$router.push({
        query: {
          ...query,
          ...val,
        },
      });
    },
    findOrders() {
      this.GET_BUNDLE_ORDERS({ ...this.$route.query });
    },
    findOrdersCustomer() {
      this.GET_BUNDLE_ORDERS({ customerName: this.customerNameFilter });
    },
    async querySearch() {
      const valid = await this.$validator.validate('form1.*');
      if (!valid) {
        return false;
      }
      if (this.filtersChanged) {
        this.updateQueryFilter();
      } else {
        this.findOrders();
      }
      await this.$validator.reset();
    },
    async querySearchCustomer() {
      const valid = await this.$validator.validate('form2.*');
      if (!valid) {
        return false;
      }
      this.findOrdersCustomer();
    },
    handleFilterVisibility() {
      this.filterVisible = !this.filterVisible;
      localStorage.setItem('idream-bo-filter-visible', this.filterVisible);
    },
    showModal() {
      this.isStatusInfoModalVisible = true;
    },
    changeModalVisibility(event) {
      this.isStatusInfoModalVisible = event;
    },
  },
  computed: {
    ...mapState(['backOffice', 'pickUpStores']),
    ...mapGetters(['authenticatedUser', 'isAdmin']),
    filters() {
      const statuses = mapToOptionValue(this.statusFilter);
      const creators = mapToOptionValue(this.creatorFilter);
      const countries = mapToOptionValue(this.countryFilter);
      const stores = mapToOptionValue(this.storeFilter);
      const idealReference = this.idealReferenceFilter;
      const referenceNumber = this.referenceNumberFilter;
      const startDate = this.range.start;
      const endDate = this.range.end;

      return {
        statuses,
        startDate,
        endDate,
        creators,
        countries,
        stores,
        idealReference,
        referenceNumber,
        size: [this.pageSize],
      };
    },
    filterStatusOptions() {
      return Object.keys(this.ORDER_STATUSES).map((status) => ({
        key: 'statuses',
        value: status,
        label: this.$t(`ORDER_STATUSES.${status}`),
        color: orderStatusVariant(status),
      }));
    },
    filterCountryOptions() {
      return this.authenticatedUser.countries.map((countryCode) => ({
        key: 'countries',
        value: countryCode,
        label: this.$t(`COUNTRIES.${countryCode}`),
      }));
    },
    filterCreatedByOptions() {
      return this.backOffice.creators.map((creator) => ({
        key: 'creators',
        value: creator.id.toString(),
        label: creator.email,
      }));
    },
    filterStoreOptions() {
      return this.pickUpStores.map((store) => ({
        key: 'stores',
        value: store.code,
        label: store.name,
      }));
    },
    pageSizeOptions() {
      return [10, 20, 50, 100];
    },
  },
};
</script>
