// This file is quite pointless without custom VeeValidate.extend methods
// but it is here for the setup for future changes
import validationMessagesEN from 'vee-validate/dist/locale/en';
import validationMessagesET from 'vee-validate/dist/locale/et';
import validationMessagesLV from 'vee-validate/dist/locale/lv';
import validationMessagesLT from 'vee-validate/dist/locale/lt';
import validationMessagesRU from 'vee-validate/dist/locale/ru';
import validationMessagesPL from 'vee-validate/dist/locale/pl';

import { Validator } from 'vee-validate';
import {
  validateBalticsAndPolishPhone,
  validateBigbankPhoneEE,
  validateBigbankPhoneLT,
  validatePersonalCodePL,
  validatePersonalCodeEEAndLT,
  validatePersonalCodeLV,
  dateOfBirthLV,
  dateOfBirthLVAge,
  validateBigbankPhoneLV,
  validateInbankPhoneEE,
  validateInbankPhoneLV,
  validateInbankPhoneLT,
  validateInbankPhonePL,
  isUsernameCompanyEmail,
} from './utils/validation.js';

export const dictionary = {
  en: {
    ...validationMessagesEN,
    messages: {
      ...validationMessagesEN.messages,
      length: (_, length) => `The ${_} length must be ${length[0]}`,
      dateOfBirthLVAge: `It’s not possible to make hire purchase offer. For more information, please contact Bigbank customer support info@bigbank.lv or 8338`,
    },
    custom: {
      username: {
        companyEmail: () =>
          'The e-mail entered is not a company e-mail address',
      },
    },
  },
  et: {
    ...validationMessagesET,
    messages: {
      ...validationMessagesET.messages,
      length: (_, length) => `Väli ${_} peab olema ${length[0]} tähemärki pikk`,
    },
  },
  lv: {
    ...validationMessagesLV,
    messages: {
      ...validationMessagesLV.messages,
      length: (_, length) => `Lauka ${_} garumam jābūt ${length[0]}`,
      dateOfBirthLVAge: `Nav iespējams veikt pirkuma piedāvājumu. Lai saņemtu plašāku informāciju, lūdzu, sazinieties ar Bigbank klientu atbalsta komandu - info@bigbank.lv vai 8338`,
    },
  },
  lt: {
    ...validationMessagesLT,
    messages: {
      ...validationMessagesLT.messages,
      length: (_, length) => `The ${_} length must be ${length[0]}`,
    },
  },
  ru: {
    ...validationMessagesRU,
    messages: {
      ...validationMessagesRU.messages,
      length: (_, length) => `Длина поля ${_} должна быть ${length[0]}`,
      dateOfBirthLVAge: `Предложение о покупке в рассрочку невозможно. За дополнительной информацией обращайтесь в службу поддержки клиентов info@bigbank.lv или 8338`,
    },
  },
  pl: {
    ...validationMessagesPL,
    messages: {
      ...validationMessagesPL.messages,
      length: (_, length) => `The ${_} length must be ${length[0]}`,
    },
    custom: {
      username: {
        companyEmail: () => 'Podany e-mail nie jest firmowym adresem e-mail',
      },
    },
  },
};

Validator.extend('personalCodePL', (personalCode) =>
  validatePersonalCodePL(personalCode),
);

Validator.extend('personalCodeEEAndLT', (personalCode) =>
  validatePersonalCodeEEAndLT(personalCode),
);

Validator.extend('personalCodeLV', (personalCode) =>
  validatePersonalCodeLV(personalCode),
);

Validator.extend('balticsAndPolishPhone', (phoneObjectOrNumber) =>
  validateBalticsAndPolishPhone(phoneObjectOrNumber),
);

Validator.extend('bigbankPhoneEE', (phoneObjectOrNumber) =>
  validateBigbankPhoneEE(phoneObjectOrNumber),
);

Validator.extend('bigbankPhoneLV', (phoneObjectOrNumber) =>
  validateBigbankPhoneLV(phoneObjectOrNumber),
);

Validator.extend('bigbankPhoneLT', (phoneObjectOrNumber) =>
  validateBigbankPhoneLT(phoneObjectOrNumber),
);

Validator.extend('inbankPhoneEE', (phoneObjectOrNumber) =>
  validateInbankPhoneEE(phoneObjectOrNumber),
);

Validator.extend('inbankPhoneLV', (phoneObjectOrNumber) =>
  validateInbankPhoneLV(phoneObjectOrNumber),
);

Validator.extend('inbankPhoneLT', (phoneObjectOrNumber) =>
  validateInbankPhoneLT(phoneObjectOrNumber),
);

Validator.extend('inbankPhonePL', (phoneObjectOrNumber) =>
  validateInbankPhonePL(phoneObjectOrNumber),
);

Validator.extend('dateOfBirthLV', (dateOfBirth) => dateOfBirthLV(dateOfBirth));

Validator.extend('dateOfBirthLVAge', (dateOfBirth) =>
  dateOfBirthLVAge(dateOfBirth),
);

Validator.extend('companyEmail', (username) =>
  isUsernameCompanyEmail(username),
);
