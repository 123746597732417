<template>
  <div>
    <Separator hasLine size="medium" />

    <b-row align-h="center">
      <b-col cols="8" sm="5" md="3">
        <ApplyAssetCard />
      </b-col>
      <b-col md="9">
        <Margins class="margins__mobile-md-double">
          <h4>{{ bundle.name }}</h4>
          <YourPlanList />
          <Progress
            :value="bundle.upgradePeriod"
            :max="bundle.totalPeriod"
            :unitLabel="$t('MONTHS')"
          />
          <table v-if="isStorePolish" class="table">
            <tbody>
              <tr style="border-top: none">
                <td class="no-padding">
                  {{ $t('YOUR_PLAN_TABLE.PL_CATEGORY_4') }}
                </td>
                <td class="text-right text-normal no-padding whitespace-nowrap">
                  {{ bundle.retailPrice.toFixed(2) }}
                  {{ $t('CURRENCY_PL') }}
                </td>
              </tr>
              <tr style="border-top: none">
                <td class="no-padding">
                  {{ $t('YOUR_PLAN_TABLE.PL_CATEGORY_3') }}
                </td>
                <td class="text-right text-normal no-padding whitespace-nowrap">
                  {{ bundle.totalAmount.toFixed(2) }}
                  {{ $t('CURRENCY_PL') }}
                </td>
              </tr>
              <tr style="border-top: none">
                <td class="no-padding">
                  <strong>
                    {{ $t('YOUR_PLAN_TABLE.CATEGORY_TOTAL') }}
                  </strong>
                </td>
                <td class="text-right text-normal no-padding whitespace-nowrap">
                  <strong>
                    {{ bundle.monthlyPaymentAmount.toFixed(2) }}
                    {{ $t('CURRENCY_PL') }}
                  </strong>
                </td>
              </tr>
              <!--
              TODO: remove comment once residual value can be retrieved from back-office
              https://fairown.atlassian.net/browse/PBS-27
              <tr style="border-top: none">
                <td class="no-padding">
                  {{
                    $t('YOUR_PLAN_TABLE.PL_CATEGORY_2', [bundle.upgradePeriod])
                  }}
                </td>

                <td class="text-right text-normal no-padding whitespace-nowrap">
                  {{
                    (
                      (bundle.totalPeriod - bundle.upgradePeriod) *
                      bundle.monthlyPaymentAmount
                    ).toFixed(2)
                  }}
                  {{ $t('CURRENCY_PL') }}
                </td>
              </tr>
              -->
              <tr style="border-top: none">
                <td class="no-padding">
                  {{
                    $t('YOUR_PLAN_TABLE.PL_CATEGORY_1', [bundle.upgradePeriod])
                  }}
                </td>
                <td class="text-right text-normal no-padding whitespace-nowrap">
                  {{
                    (
                      bundle.monthlyPaymentAmount * bundle.upgradePeriod
                    ).toFixed(2)
                  }}
                  {{ $t('CURRENCY_PL') }}
                </td>
              </tr>
            </tbody>
          </table>
          <table v-else class="table">
            <tbody>
              <tr>
                <td class="h5">
                  {{ $t('YOUR_PLAN_TABLE.CATEGORY_TOTAL') }}
                </td>
                <td class="text-right text-normal">
                  <span class="h5">
                    {{ bundle.monthlyPaymentAmount.toFixed(2) }}
                    {{ $t('CURRENCY') }}
                  </span>
                  /
                  <span>{{ $t('MONTH_ABBREVIATION') }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </Margins>
      </b-col>
    </b-row>

    <Separator hasLine size="medium" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ApplyAssetCard, YourPlanList } from '@/containers/Apply';
import { Margins, Progress, Separator } from '@/components';

export default {
  name: 'Product',
  components: {
    ApplyAssetCard,
    Margins,
    Progress,
    YourPlanList,
    Separator,
  },
  computed: {
    ...mapGetters(['asset', 'bundle', 'isStorePolish']),
  },
};
</script>

<style></style>
