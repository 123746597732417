<template>
  <div class="multi-select">
    <div class="d-inline-flex mb-1">
      <label :for="id" class="multi-select__label mr-1">
        {{ label }}
      </label>
      <slot name="icon"></slot>
    </div>
    <Multiselect
      ref="multiselect"
      :id="id"
      :name="name"
      :value="value"
      :options="options"
      :searchable="searchable"
      :multiple="multiple"
      :preserve-search="preserveSearch"
      :close-on-select="closeOnSelect"
      :show-labels="showLabels"
      @input="input"
      @select="select"
      @remove="remove"
      @search-change="searchChange"
      :placeholder="placeholder"
      :label="label"
      :track-by="trackBy"
      :aria-invalid="!!error"
      :aria-describedby="`error-${name}`"
      :max-height="maxHeight"
      :internal-search="internalSearch"
    >
      <template slot="noResult">{{ noResult }}</template>
      <template slot="noOptions">{{ noOptions }}</template>
      <template slot="tag" slot-scope="{ option, remove }">
        <Badge
          :variant="option.color || 'primary'"
          hasRemoveButton
          borderRadius="small"
          @click="remove(option)"
        >
          {{ option.label }}
        </Badge>
      </template>
      <template #option="{ option }">
        <span>{{ option.label }}</span>
      </template>
      <template #singleLabel="{ option }">
        {{ option.label }}
      </template>
      <template #afterList>
        <slot name="afterList"></slot>
      </template>
    </Multiselect>
    <p v-if="error" :id="`error-${name}`" class="multi-select__error">
      {{ error }}
    </p>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import Badge from './Badge.vue';

export default {
  name: 'MultiSelect',
  inject: ['$validator'],
  components: {
    Multiselect,
    Badge,
  },
  props: {
    id: String,
    name: {
      type: String,
      required: true,
    },
    value: [String, Object, Array, Number],
    options: {
      type: Array,
      required: true,
    },
    searchable: Boolean,
    multiple: { type: Boolean, default: false },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    showLabels: Boolean,
    placeholder: String,
    label: String,
    trackBy: {
      type: String,
      default: 'value',
    },
    noResult: String,
    noOptions: String,
    error: String,
    maxHeight: {
      type: Number,
      default: 150,
    },
    internalSearch: {
      type: Boolean,
      default: true,
    },
    preserveSearch: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    input(value) {
      this.$emit('input', value);
    },
    select(value) {
      this.$emit('select', value);
    },
    remove(value) {
      this.$emit('remove', value);
    },
    searchChange(value) {
      this.$emit('search-change', value);
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.multi-select__label {
  display: block;
  font-size: $font-size-extra-small;
  margin-bottom: 0.25rem;
}

.multi-select__error {
  color: $color-error;
  font-size: $font-size-small;
  margin-top: rem(8px);
}

.multiselect {
  min-height: auto;
}

.multiselect__select {
  height: 36px;
}

.multiselect__tags {
  border-radius: $border-radius;
  border: rem(1px) solid $color-grey-border;
  padding: rem(3px) rem(40px) rem(3px) rem(4px);
  min-height: rem(36px);

  .multiselect--active & {
    border-color: $color-primary;
    box-shadow: 0 0 0 4px rgba($color-primary, 0.6);
    outline: none;
  }
}

.multiselect__tags-wrap > * {
  margin: rem(2px);
}

.multiselect__content-wrapper {
  box-shadow: $elevation;
}

.multiselect__element + .multiselect__element {
  border-top: 1px solid $color-grey-border;
}

.multiselect__option {
  font-size: $font-size-small;
  cursor: default;
}

.multiselect__option--highlight {
  background: $color-primary;
  cursor: pointer;
}

.multiselect__option--selected {
  background: $color-grey-light;
  color: $color-black-light;
  font-weight: $font-weight-bold;
  cursor: default;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: $color-grey-light;
  color: $color-black-light;
}

.multiselect__input {
  font-weight: $font-weight-bold;
  padding: rem(4px) rem(3px);
  margin-bottom: 0;
}

.multiselect__placeholder,
.multiselect__input::placeholder {
  color: $color-grey;
  font-size: $font-size-small;
  font-weight: $font-weight;
}

.multiselect__single,
.multiselect__placeholder {
  margin-bottom: 0;
  padding-top: rem(4px);
  padding-bottom: rem(5px);
  padding-left: rem(3px);
}

.multiselect__single {
  font-weight: $font-weight-bold;
}
</style>
