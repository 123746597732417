<template>
  <Card color="grey" padding="small">
    <List :items="bulletsList" variant="bullets" class="text-small" />
  </Card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { List, Card } from '@/components';
import { constants } from '@/mixins';

export default {
  name: 'YourPlanList',
  mixins: [constants],
  components: {
    List,
    Card,
  },
  computed: {
    ...mapGetters(['bundle', 'isStorePolish']),
    ...mapState(['language']),
    bulletsList() {
      if (!this.bundle.totalPeriod) {
        return [];
      }

      const { totalPeriod, upgradePeriod } = this.bundle;

      if (this.isStorePolish) {
        return [
          this.$t('YOUR_PLAN_BULLETS_LIST.PL_ITEM_1', [totalPeriod]),
          this.$t('YOUR_PLAN_BULLETS_LIST.PL_ITEM_2', [upgradePeriod]),
          this.$t('YOUR_PLAN_BULLETS_LIST.PL_ITEM_3', [totalPeriod]),
        ];
      }

      if (this.language === this.LOCALES.LV) {
        return [
          this.$t('YOUR_PLAN_BULLETS_LIST.ITEM_1', [
            totalPeriod,
            upgradePeriod,
          ]),
          this.$t('YOUR_PLAN_BULLETS_LIST.ITEM_2', [upgradePeriod]),
          this.$t('YOUR_PLAN_BULLETS_LIST.ITEM_3', [
            upgradePeriod,
            totalPeriod - upgradePeriod,
          ]),
        ];
      } else {
        return [
          this.$t('YOUR_PLAN_BULLETS_LIST.ITEM_1', [totalPeriod]),
          this.$t('YOUR_PLAN_BULLETS_LIST.ITEM_2', [upgradePeriod]),
          this.$t('YOUR_PLAN_BULLETS_LIST.ITEM_3', [totalPeriod]),
        ];
      }
    },
  },
};
</script>
