import {
  API_BASE_URL,
  DECISION_TYPES,
  ORDER_STATUSES,
  REGEX,
  ROUTES,
  SERVICE_TYPES,
  SIGNATURE_STATUSES,
  STEPS,
  LOCALES,
  STORE,
  DELIVERY_TYPES,
  COUNTRY,
  CURRENCY_CODE,
} from '@/constants';
import { parseServicePeriod, isMultipleYears } from '@/utils';

export const constants = {
  computed: {
    API_BASE_URL() {
      return API_BASE_URL;
    },
    ROUTES() {
      return ROUTES;
    },
    SERVICE_TYPES() {
      return SERVICE_TYPES;
    },
    STEPS() {
      return STEPS;
    },
    REGEX() {
      return REGEX;
    },
    DECISION_TYPES() {
      return DECISION_TYPES;
    },
    SIGNATURE_STATUSES() {
      return SIGNATURE_STATUSES;
    },
    ORDER_STATUSES() {
      return ORDER_STATUSES;
    },
    LOCALES() {
      return LOCALES;
    },
    STORE() {
      return STORE;
    },
    DELIVERY_TYPES() {
      return DELIVERY_TYPES;
    },
    COUNTRY() {
      return COUNTRY;
    },
    IS_MAINTENANCE() {
      return process.env.VUE_APP_MAINTENANCE === 'true';
    },
  },
};

export const getServicePeriod = {
  methods: {
    getServicePeriod(period) {
      const parsedPeriod = parseServicePeriod(period);

      if (!parsedPeriod) {
        return '';
      }

      return `${this.$tc(
        `${parsedPeriod.period.toUpperCase()}`,
        isMultipleYears(period),
      )} ${parsedPeriod.time}`;
    },
  },
};

export const formatValues = {
  methods: {
    formatPrice(price) {
      return price === null || price === 0
        ? `0 ${CURRENCY_CODE.PLN}`
        : `${Number.parseFloat(price).toFixed(2)} ${CURRENCY_CODE.PLN}`;
    },
  },
};
