export const RESET_STORE = 'RESET_STORE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_STORE_OPTIONS = 'SET_STORE_OPTIONS';
export const IS_LOADING = 'IS_LOADING';
export const HAS_ERROR = 'HAS_ERROR';
export const NOTIFICATION = 'NOTIFICATION';

export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';

export const SET_SELECTED_BUNDLE_AND_ASSET = 'SET_SELECTED_BUNDLE_AND_ASSET';

export const SET_LOAN_APPLICATION_DATA = 'SET_LOAN_APPLICATION_DATA';
export const SUBMIT_LOAN_APPLICATION = 'SUBMIT_LOAN_APPLICATION';
export const HAS_LOAN_APPLICATION_ERROR = 'HAS_LOAN_APPLICATION_ERROR';
export const LOAN_APPLICATION_ERROR_MESSAGE = 'LOAN_APPLICATION_ERROR_MESSAGE';
export const HANDLE_APPLICATION_RESPONSE = 'HANDLE_APPLICATION_RESPONSE';
export const GET_APPLICATION_STATUS_AND_REDIRECT =
  'GET_APPLICATION_STATUS_AND_REDIRECT';
export const GET_APPLICATION_STATUS = 'GET_APPLICATION_STATUS';
export const SET_APPLICATION_STATUS = 'SET_APPLICATION_STATUS';
export const REFRESH_APPLICATION_STATUS = 'REFRESH_APPLICATION_STATUS';
export const SET_BUNDLE_ORDER = 'SET_BUNDLE_ORDER';
export const SET_LOAN_APPLICATION_DECISION = 'SET_LOAN_APPLICATION_DECISION';

export const GET_STORE_FROM_SHORT_NAME = 'GET_STORE_FROM_SHORT_NAME';
export const SET_CURRENT_STORE = 'SET_CURRENT_STORE';

export const GET_BUNDLE_FROM_PRODUCT_CODE = 'GET_BUNDLE_FROM_PRODUCT_CODE';
export const SET_BUNDLE_PRODUCT = 'SET_BUNDLE_PRODUCT';

export const GET_CITIZEN_COUNTRY_CODES = 'GET_CITIZEN_COUNTRY_CODES';
export const SET_CITIZEN_COUNTRY_CODES = 'SET_CITIZEN_COUNTRY_CODES';

export const GET_PICKUP_STORES = 'GET_PICKUP_STORES';
export const SET_PICKUP_STORES = 'SET_PICKUP_STORES';
export const GET_OMNIVA_MACHINES = 'GET_OMNIVA_MACHINES';
export const SET_OMNIVA_MACHINES = 'SET_OMNIVA_MACHINES';
export const SUBMIT_DELIVERY_INFO = 'SUBMIT_DELIVERY_INFO';
export const REDIRECT_TO_MERCHANT = 'REDIRECT_TO_MERCHANT';
export const REDIRECT_TO_MERCHANT_WITH_TIMEOUT =
  'REDIRECT_TO_MERCHANT_WITH_TIMEOUT';

export const POLL_APPLICATION_RESPONSE = 'POLL_APPLICATION_RESPONSE';

export const SET_BUNDLE_FROM_REFERENCE_NUMBER =
  'SET_BUNDLE_FROM_REFERENCE_NUMBER';

// Back office
export const GET_BUNDLE_ORDER_CREATORS = 'GET_BUNDLE_ORDER_CREATORS';
export const SET_BUNDLE_ORDER_CREATORS = 'SET_BUNDLE_ORDER_CREATORS';
export const GET_BUNDLE_ORDERS = 'GET_BUNDLE_ORDERS';
export const SET_BUNDLE_ORDERS = 'SET_BUNDLE_ORDERS';
export const GET_BUNDLE_ORDER_DETAILS = 'GET_BUNDLE_ORDER_DETAILS';
export const SET_ORDER_HANDED_OVER = 'SET_ORDER_HANDED_OVER';
export const SET_BACK_OFFICE_LOGGED_IN = 'SET_BACK_OFFICE_LOGGED_IN';
export const BACK_OFFICE_LOGIN = 'BACK_OFFICE_LOGIN';
export const BACK_OFFICE_LOGOUT = 'BACK_OFFICE_LOGOUT';
export const BACK_OFFICE_PASSWORD_LOST = 'BACK_OFFICE_PASSWORD_LOST';
export const BACK_OFFICE_PASSWORD_RESET = 'BACK_OFFICE_PASSWORD_RESET';
export const BACK_OFFICE_PASSWORD_RESET_CHECK =
  'BACK_OFFICE_PASSWORD_RESET_CHECK';
export const ACCEPT_INVITATION = 'ACCEPT_INVITATION';
export const GET_BUNDLES = 'GET_BUNDLES';
export const FETCH_PRODUCT_IMAGE = 'FETCH_PRODUCT_IMAGE';
export const DOES_BUNDLE_EXIST = 'DOES_BUNDLE_EXIST';
export const GET_BUNDLES_PAGINATED = 'GET_BUNDLES_PAGINATED';
export const GET_BUNDLES_PAGINATED_WITHOUT_LOADING =
  'GET_BUNDLES_PAGINATED_WITHOUT_LOADING';
export const SET_ALL_BUNDLES = 'SET_ALL_BUNDLES';
export const GET_INVITATION = 'GET_INVITATION';
export const SEND_INVITATIONS = 'SEND_INVITATIONS';
export const SET_BACK_OFFICE_USER = 'SET_BACK_OFFICE_USER';
export const ANNUL_ORDER = 'ANNUL_ORDER';
export const CONTINUE_AS_CUSTOMER = 'CONTINUE_AS_CUSTOMER';
export const SEND_CUSTOMER_CONTINUE_LINK = 'SEND_CUSTOMER_CONTINUE_LINK';
export const SET_BUNDLES_PAGINATED_RESPONSE = 'SET_BUNDLES_PAGINATED_RESPONSE';
export const DEACTIVATE_BUNDLES = 'DEACTIVATE_BUNDLES';
export const EDIT_BUNDLE = 'EDIT_BUNDLE';
export const CREATE_BUNDLE = 'CREATE_BUNDLE';
export const GET_USERS = 'GET_USERS';
export const GET_STORES = 'GET_STORES';
export const SET_STORES = 'SET_STORES';
export const SET_USERS = 'SET_USERS';
export const DEACTIVATE_USERS = 'DEACTIVATE_USERS';
export const CREATE_USER = 'CREATE_USER';
export const SEND_INVITATION = 'SEND_INVITATION';
