var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'select',
    {
      'has-error': _vm.error,
      [`select--${_vm.size}`]: _vm.size,
      [`select--${_vm.theme}`]: _vm.theme,
    },
  ]},[_c('label',{class:['select__label', { 'sr-only': _vm.labelHidden }],attrs:{"for":_vm.name}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{staticClass:"select__content"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.val),expression:"val"}],staticClass:"select__input",attrs:{"id":_vm.name,"name":_vm.name,"aria-invalid":!!_vm.error,"aria-describedby":`error-${_vm.name}`},on:{"input":_vm.updateSelect,"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.val=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.changeSelect]}},_vm._l((_vm.options),function(option,index){return _c('option',{key:index,attrs:{"disabled":option.disabled},domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0),_c('ChevronDownSVG',{staticClass:"icon select__icon"})],1),(_vm.error)?_c('p',{staticClass:"select__error",attrs:{"id":`error-${_vm.name}`}},[_vm._v(_vm._s(_vm.error))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }