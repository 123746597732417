import Vue from 'vue';
import * as types from './types';
import ApiService from '../api';
import { ORDER_STATUSES, ROUTES, USER_ROLE } from '../constants';
import router from '../router';
import { changeProperty, getPersistedStore } from '../utils';

const persistedStore = getPersistedStore();

export default {
  state: {
    bundleOrders: {
      content: [],
      nav: {},
    },
    creators: [],
    startDate: [],
    endDate: [],
    idealReferences: [],
    referenceNumbers: [],
    loggedIn: persistedStore.backOffice?.loggedIn || false,
    allBundles: [],
    allStores: [],
    activeUsers: {
      content: [],
      pageable: {},
      totalPages: 0,
      number: 0,
      last: false,
      first: true,
    },
    bundles: {
      content: [],
      pageable: {},
      totalPages: 0,
      number: 0,
      last: false,
      first: true,
    },
    authenticatedUser: persistedStore.backOffice?.authenticatedUser || null,
  },
  actions: {
    async [types.REFRESH_APPLICATION_STATUS](
      { commit, state },
      referenceNumber,
    ) {
      try {
        const bundleOrder = await ApiService.getApplicationStatusUpdate(
          referenceNumber,
        );
        const index = state.bundleOrders.content.findIndex(
          (o) => o.referenceNumber === referenceNumber,
        );
        commit(types.SET_BUNDLE_ORDER, { index, bundleOrder });
      } catch (error) {
        commit(types.HAS_LOAN_APPLICATION_ERROR, error);
      }
    },
    async [types.GET_BUNDLE_ORDERS]({ commit }, data) {
      try {
        commit(types.IS_LOADING, true);

        const orders = await ApiService.getBundleOrders(data);

        commit(types.SET_BUNDLE_ORDERS, orders);
      } catch (error) {
        commit(types.HAS_ERROR, error);
      } finally {
        commit(types.IS_LOADING, false);
      }
    },
    async [types.GET_BUNDLE_ORDER_CREATORS]({ commit }) {
      try {
        commit(types.IS_LOADING, true);

        const creators = await ApiService.getOrderCreators();

        commit(types.SET_BUNDLE_ORDER_CREATORS, creators);
      } catch (error) {
        commit(types.HAS_ERROR, error);
      } finally {
        commit(types.IS_LOADING, false);
      }
    },
    async [types.GET_BUNDLE_ORDER_DETAILS]({ commit }, referenceNumber) {
      try {
        return await ApiService.getBundleOrderDetails(referenceNumber);
      } catch (error) {
        commit(types.HAS_ERROR, error);
      }
    },
    async [types.SET_ORDER_HANDED_OVER]({ commit }, data) {
      try {
        commit(types.IS_LOADING, true);
        await ApiService.handOver(data);
        commit(types.SET_ORDER_HANDED_OVER, data);
      } catch (error) {
        commit(types.HAS_ERROR, error);
      } finally {
        commit(types.IS_LOADING, false);
      }
    },
    async [types.BACK_OFFICE_LOGIN]({ commit, dispatch }, formData) {
      try {
        const user = await ApiService.login(formData);
        commit(types.SET_BACK_OFFICE_USER, user);
        commit(types.SET_BACK_OFFICE_LOGGED_IN, true);
        if (user.role === USER_ROLE.EMPLOYEE) {
          commit(types.SET_CURRENT_STORE, user.store);
          dispatch(types.SET_STORE_OPTIONS, user.store);
        } else {
          commit(types.SET_CURRENT_STORE, null);
          dispatch(types.SET_STORE_OPTIONS, { country: 'XX' });
        }
      } catch (error) {
        commit(types.HAS_ERROR, error);
      }
    },
    async [types.BACK_OFFICE_LOGOUT]({ commit }) {
      console.debug('backoffice logout');
      try {
        await ApiService.logout();
      } catch (error) {
        commit(types.HAS_ERROR, error);
      } finally {
        commit(types.SET_BACK_OFFICE_USER, null);
        commit(types.SET_BACK_OFFICE_LOGGED_IN, false);
        router.push({ name: ROUTES.BACK_OFFICE_LOGIN.name });
      }
    },
    async [types.CONTINUE_AS_CUSTOMER]({ commit }, data) {
      let continueAsCustomerResponse;
      try {
        continueAsCustomerResponse = await ApiService.continueAsCustomer(
          data.bundleId,
        );
        await ApiService.logout();
      } catch (error) {
        commit(types.HAS_ERROR, error);
      } finally {
        commit(types.SET_BACK_OFFICE_USER, null);
        commit(types.SET_BACK_OFFICE_LOGGED_IN, false);
      }

      if (continueAsCustomerResponse) {
        window.location = continueAsCustomerResponse.url;
      }
    },
    async [types.SEND_CUSTOMER_CONTINUE_LINK]({ commit }, data) {
      try {
        await ApiService.continueAsCustomerWithSMS(data);
      } catch (error) {
        commit(types.HAS_ERROR, error);
        throw new Error(error);
      }
    },
    async [types.ACCEPT_INVITATION]({ commit }, data) {
      try {
        await ApiService.acceptInvitation(data.invitationId, data.formData);
        commit(types.NOTIFICATION, data.notification);

        setTimeout(
          () => router.push({ name: ROUTES.BACK_OFFICE_LOGIN.name }),
          5000,
        );
      } catch (error) {
        commit(types.HAS_ERROR, error);
        throw new Error(error);
      }
    },
    async [types.GET_INVITATION]({ commit, dispatch }, invitationId) {
      try {
        commit(types.IS_LOADING, true);

        const invitation = await ApiService.getInvitation(invitationId);
        const store =
          invitation.role === USER_ROLE.EMPLOYEE
            ? invitation.store
            : { country: 'XX' };
        dispatch(types.SET_STORE_OPTIONS, store);
      } catch (error) {
        commit(types.HAS_ERROR, error);
      } finally {
        commit(types.IS_LOADING, false);
      }
    },
    async [types.SEND_INVITATIONS]({ commit }) {
      try {
        commit(types.IS_LOADING, true);
        await ApiService.sendInvitations();
      } catch (error) {
        commit(types.HAS_ERROR, error);
      } finally {
        commit(types.IS_LOADING, false);
      }
    },
    async [types.BACK_OFFICE_PASSWORD_LOST]({ commit, rootState }, data) {
      try {
        commit(types.IS_LOADING, true);
        const request = {
          ...data.formData,
          language: rootState.language,
        };
        await ApiService.requestPasswordReset(request);
        commit(types.NOTIFICATION, data.notification);
      } catch (error) {
        commit(types.HAS_ERROR, error);
      } finally {
        commit(types.IS_LOADING, false);
      }
    },
    async [types.BACK_OFFICE_PASSWORD_RESET_CHECK]({ commit }, tokenId) {
      try {
        commit(types.IS_LOADING, true);
        await ApiService.resetPasswordCheck(tokenId);
      } catch (error) {
        commit(types.HAS_ERROR, error);
        setTimeout(
          () => router.push({ name: ROUTES.BACK_OFFICE_LOGIN.name }),
          5000,
        );
      } finally {
        commit(types.IS_LOADING, false);
      }
    },
    async [types.BACK_OFFICE_PASSWORD_RESET]({ commit, rootState }, data) {
      try {
        commit(types.IS_LOADING, true);
        const request = {
          ...data.formData,
          language: rootState.language,
        };
        await ApiService.resetPassword(data.tokenId, request);
        commit(types.NOTIFICATION, data.notification);

        setTimeout(
          () => router.push({ name: ROUTES.BACK_OFFICE_LOGIN.name }),
          5000,
        );
      } catch (error) {
        commit(types.HAS_ERROR, error);
      } finally {
        commit(types.IS_LOADING, false);
      }
    },
    async [types.GET_BUNDLES]({ state, commit }) {
      try {
        commit(types.IS_LOADING, true);
        if (state.allBundles.length === 0) {
          const allBundles = await ApiService.getBundles();
          commit(types.SET_ALL_BUNDLES, allBundles);
        }
      } catch (error) {
        commit(types.HAS_ERROR, error);
      } finally {
        commit(types.IS_LOADING, false);
      }
    },
    async [types.FETCH_PRODUCT_IMAGE]({ state, commit }, assetId) {
      try {
        return await ApiService.fetchProductPictureAsBlob(assetId);
      } catch (error) {
        commit(types.HAS_ERROR, error);
      }
    },
    async [types.DOES_BUNDLE_EXIST]({ state, commit }, bundle) {
      try {
        return await ApiService.doesBundleExist(bundle);
      } catch (error) {
        commit(types.HAS_ERROR, error);
      }
    },
    async [types.GET_BUNDLES_PAGINATED]({ state, commit }, data) {
      try {
        commit(types.IS_LOADING, true);
        const response = await ApiService.getBundlesPaginatedResponse(data);
        commit(types.SET_BUNDLES_PAGINATED_RESPONSE, response);
      } catch (error) {
        commit(types.HAS_ERROR, error);
      } finally {
        commit(types.IS_LOADING, false);
      }
    },
    async [types.GET_BUNDLES_PAGINATED_WITHOUT_LOADING](
      { state, commit },
      data,
    ) {
      try {
        const response = await ApiService.getBundlesPaginatedResponse(data);
        commit(types.SET_BUNDLES_PAGINATED_RESPONSE, response);
      } catch (error) {
        commit(types.HAS_ERROR, error);
      }
    },
    async [types.DEACTIVATE_BUNDLES]({ state, commit }, bundleIds) {
      try {
        commit(types.IS_LOADING, true);
        await ApiService.deactivateBundles(bundleIds);
      } catch (error) {
        commit(types.HAS_ERROR, error);
      } finally {
        commit(types.IS_LOADING, false);
      }
    },
    async [types.DEACTIVATE_USERS]({ state, commit }, userIds) {
      try {
        commit(types.IS_LOADING, true);
        await ApiService.deactivateUsers(userIds);
      } catch (error) {
        commit(types.HAS_ERROR, error);
      } finally {
        commit(types.IS_LOADING, false);
      }
    },
    async [types.CREATE_USER]({ commit }, user) {
      try {
        commit(types.IS_LOADING, true);
        await ApiService.createUser(user);
      } catch (error) {
        commit(types.HAS_ERROR, error);
      } finally {
        commit(types.IS_LOADING, false);
      }
    },
    async [types.SEND_INVITATION]({ commit }, id) {
      try {
        commit(types.IS_LOADING, true);
        await ApiService.sendInvitation(id);
      } catch (error) {
        commit(types.HAS_ERROR, error);
      } finally {
        commit(types.IS_LOADING, false);
      }
    },
    async [types.EDIT_BUNDLE]({ state, commit }, data) {
      try {
        await ApiService.editBundle(data);
      } catch (error) {
        commit(types.HAS_ERROR, error);
      }
    },
    async [types.GET_USERS]({ state, commit }, data) {
      try {
        commit(types.IS_LOADING, true);
        const response = await ApiService.getPartnerUsers(data);
        commit(types.SET_USERS, response);
      } catch (error) {
        commit(types.HAS_ERROR, error);
      } finally {
        commit(types.IS_LOADING, false);
      }
    },
    async [types.GET_STORES]({ state, commit }) {
      try {
        const response = await ApiService.getAllUserStores();
        commit(types.SET_STORES, response);
      } catch (error) {
        commit(types.HAS_ERROR, error);
      } finally {
      }
    },

    async [types.CREATE_BUNDLE]({ state, commit }, data) {
      try {
        commit(types.IS_LOADING, true);
        await ApiService.createBundle(data);
      } catch (error) {
        commit(types.HAS_ERROR, error);
      } finally {
        commit(types.IS_LOADING, false);
      }
    },
    async [types.ANNUL_ORDER]({ commit, dispatch }, referenceNumber) {
      try {
        await ApiService.annulOrder(referenceNumber);
        dispatch(types.REFRESH_APPLICATION_STATUS, referenceNumber);
      } catch (error) {
        commit(types.HAS_ERROR, error);
      }
    },
  },
  getters: {
    authenticatedUser: (state) => {
      return state.authenticatedUser || {};
    },
    isAuthenticated: (state) => {
      return Boolean(state.authenticatedUser);
    },
    isAdmin: (state) => {
      return state.authenticatedUser?.role === USER_ROLE.ADMIN;
    },
    isEmployee: (state) => {
      return state.authenticatedUser?.role === USER_ROLE.EMPLOYEE;
    },
  },
  mutations: {
    [types.SET_BUNDLE_ORDER](state, { index, bundleOrder }) {
      Vue.set(state.bundleOrders.content, index, bundleOrder);
    },
    [types.SET_BUNDLE_ORDER_CREATORS](state, creators) {
      state.creators = creators;
    },
    [types.SET_BUNDLE_ORDERS](state, orders) {
      state.bundleOrders = orders;
    },
    [types.SET_USERS](state, response) {
      state.activeUsers.content = response.content;
      state.activeUsers.totalPages = response.totalPages;
      state.activeUsers.pageable = response.pageable;
      state.activeUsers.last = response.last;
      state.activeUsers.first = response.first;
      state.activeUsers.number = response.number;
    },
    [types.SET_STORES](state, response) {
      state.allStores = response;
    },
    [types.SET_ORDER_HANDED_OVER](state, data) {
      state.bundleOrders.content = changeProperty(
        state.bundleOrders.content,
        'referenceNumber',
        data.referenceNumber,
        'status',
        ORDER_STATUSES.HANDED_OVER,
      );
      state.bundleOrders.content = changeProperty(
        state.bundleOrders.content,
        'referenceNumber',
        data.referenceNumber,
        'serial',
        data.serial,
      );
    },
    [types.SET_BACK_OFFICE_LOGGED_IN](state, loggedIn) {
      state.loggedIn = loggedIn;
    },
    [types.SET_ALL_BUNDLES](state, allBundles) {
      state.allBundles = allBundles;
    },
    [types.SET_BACK_OFFICE_USER](state, user) {
      state.authenticatedUser = user;
    },
    [types.SET_BUNDLES_PAGINATED_RESPONSE](state, response) {
      state.bundles.content = response.content;
      state.bundles.totalPages = response.totalPages;
      state.bundles.pageable = response.pageable;
      state.bundles.last = response.last;
      state.bundles.first = response.first;
      state.bundles.number = response.number;
    },
  },
};
