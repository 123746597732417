<template>
  <header class="header">
    <Container>
      <div class="header__content">
        <img
          src="@/assets/images/idream_easy_logo-1.png"
          alt="iDream Easy logo"
          class="header__logo"
        />
        <ul class="header__list">
          <li
            v-for="item in headerItems"
            :key="item.label"
            class="header__list-item"
          >
            <router-link
              v-if="item.to"
              :to="item.to"
              :exact="item.exact"
              :event="item.event"
              :class="item.class"
              :title="item.label"
            >
              {{ item.label }}
            </router-link>
            <span v-else :class="item.class">{{ item.label }}</span>
          </li>
        </ul>

        <div v-if="multipleLanguages" class="header__right">
          <LanguageSwitcher />
        </div>
      </div>
    </Container>
    <Container v-if="backOffice.loggedIn">
      <ul class="header__list header__list--store">
        <li
          v-for="item in headerStoreItems"
          :key="item.label"
          class="header__list-item"
        >
          <a
            v-if="item.external"
            :href="item.to"
            target="_blank"
            rel="noopener noreferrer"
            :class="item.class"
            :title="item.label"
          >
            {{ item.label }}
          </a>
          <router-link
            v-else
            :to="item.to"
            :exact="item.exact"
            :event="item.event"
            :class="item.class"
            :title="item.label"
          >
            {{ item.label }}
          </router-link>
        </li>
        <li v-if="isAdmin" class="header__list-item">
          <router-link
            :to="ROUTES.BUNDLES.path"
            event="click"
            class="header__link is-valid"
          >
            {{ $t('ROUTER.BUNDLES') }}
          </router-link>
        </li>
        <li v-if="isAdmin || isEmployeeAdmin" class="header__list-item">
          <router-link
            :to="ROUTES.USER_MANAGEMENT.path"
            event="click"
            class="header__link is-valid"
          >
            {{ $t('ROUTER.USER_MANAGEMENT') }}
          </router-link>
        </li>
        <li class="header__list-item">
          <slot name="button-slot" />
        </li>
        <li class="header__list-item-store keep-right">
          <p class="text-small text-grey">
            {{ authenticatedUser.username }}
          </p>
        </li>
        <li class="header__list-item-store">
          <Logout />
        </li>
      </ul>
    </Container>
  </header>
</template>

<script>
import { Container } from '@/components';
import { mapGetters, mapState } from 'vuex';
import { constants } from '@/mixins';
import LanguageSwitcher from './LanguageSwitcher.vue';
import Logout from './Logout';
import { USER_ROLE } from '@/constants';

export default {
  name: 'Header',
  mixins: [constants],
  components: {
    Logout,
    Container,
    LanguageSwitcher,
  },
  computed: {
    ...mapState([
      'currentStep',
      'isLoading',
      'backOffice',
      'storeOptions',
      'productCode',
    ]),
    ...mapGetters(['authenticatedUser', 'isStorePolish', 'isAuthenticated']),
    isAdmin() {
      return this.authenticatedUser.role === USER_ROLE.ADMIN;
    },
    isEmployeeAdmin() {
      return this.authenticatedUser.role === USER_ROLE.EMPLOYEE_ADMIN;
    },
    headerItems() {
      const route404 = this.$route.name === this.ROUTES.PAGE_404.name;

      return [
        {
          label: `1. ${this.$t('HEADER.HOME')}`,
          to: {
            name: this.ROUTES.HOME.name,
            params: { productCode: this.productCode },
          },
          exact: true,
          event:
            this.isLoading || route404 || this.productCode === null
              ? ''
              : 'click',
          class: [
            'header__link',
            {
              'is-valid':
                this.currentStep >= this.STEPS.select &&
                this.productCode !== null,
            },
            {
              'is-disabled':
                this.isLoading || route404 || this.productCode === null,
            },
          ],
        },
        {
          label: `2. ${this.$t('HEADER.APPLY')}`,
          to: this.ROUTES.LOAN_APPLICATION.path,
          exact: true,
          event:
            this.isLoading || this.currentStep < this.STEPS.apply || route404
              ? ''
              : 'click',
          class: [
            'header__link',
            { 'is-valid': this.currentStep >= this.STEPS.apply },
            {
              'is-disabled':
                this.isLoading ||
                this.currentStep < this.STEPS.apply ||
                route404,
            },
          ],
        },
        {
          label: `3. ${this.$t('HEADER.SIGN')}`,
          class: [
            'header__link',
            { 'is-valid': this.currentStep >= this.STEPS.decision },
            {
              'is-disabled': this.currentStep < this.STEPS.decision || route404,
            },
          ],
        },
      ];
    },
    headerStoreItems() {
      return [
        {
          label: this.$t('BACK_OFFICE'),
          to: {
            path: this.ROUTES.BACK_OFFICE.path,
            query: { ...this.$route.query },
          },
          exact: false,
          event: 'click',
          class: ['header__link', 'is-valid'],
        },
        {
          label: this.$t('NEW_APPLICATION'),
          to: this.ROUTES.BACK_OFFICE_APPLICATION.path,
          exact: false,
          event: 'click',
          class: ['header__link', 'is-valid'],
        },
        {
          label: this.$t('FAQ'),
          to: this.ROUTES.FAQ.path,
          exact: false,
          event: 'click',
          class: ['header__link', 'is-valid'],
        },
      ];
    },
    multipleLanguages() {
      return this.storeOptions.availableLocales.length > 1;
    },
    isSmartUpgrade() {
      return (
        window.location.host === 'smart-dev.fairown.com' ||
        window.location.host === 'smart.fairown.com'
      );
    },
  },
};
</script>

<style lang="scss">
.header {
  background: $color-grey-light;
}

.header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.header__right {
  display: flex;
  align-items: center;
  order: 1;

  @include min-width(sm) {
    order: 0;
  }

  > * + * {
    margin-left: 1rem;
  }
}

.header__logo {
  height: 2.5rem;
  width: auto;
  margin: rem(4px) 0;
  order: 0;
}

.header__list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: rem(12px) 0 1rem 0;
  padding: 0;
  height: 100%;
  order: 2;
  width: 100%;

  @include min-width(sm) {
    order: 0;
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.header__list--store {
  border-top: rem(1px) solid $color-grey-border;
  justify-content: flex-start;
  margin-top: 0;
  margin-bottom: 0;

  .keep-right {
    margin-left: auto;
  }
}

.header__list-item {
  display: flex;
  align-items: center;
  height: 100%;

  + .header__list-item {
    &::before {
      content: '/';
      padding: 0 0.5rem;
      color: $color-grey-dark;

      @include min-width(md) {
        padding-left: 3rem;
        padding-right: 3rem;

        .header__list--store & {
          padding-left: 2rem;
          padding-right: 2rem;
        }
      }
    }
  }
}

.header__list-item-store {
  + .header__list-item-store {
    margin-left: 1rem;
  }
}

.header__link {
  display: flex;
  align-items: center;
  color: $color-grey-dark;
  font-size: $font-size-small;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;

  &.router-link-active,
  &.is-valid {
    color: $color-primary;
  }

  &.is-disabled {
    cursor: default;

    &:hover {
      text-decoration: none;
    }
  }

  .header__list--store & {
    height: rem(40px);
  }
}
</style>
