<template>
  <div class="your-plan">
    <Margins>
      <h3>{{ asset.name }}, {{ asset.productCode }}</h3>

      <YourPlanList class="margins__double" />
      <Progress
        :value="bundle.upgradePeriod"
        :max="bundle.totalPeriod"
        :unitLabel="$t('MONTHS')"
        class="margins__double"
      />

      <YourPlanTable class="margins__double" />
    </Margins>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Margins, Progress } from '@/components';
import YourPlanTable from './YourPlanTable.vue';
import YourPlanList from './YourPlanList.vue';
import { constants } from '@/mixins';

export default {
  name: 'YourPlan',
  mixins: [constants],
  components: {
    Margins,
    Progress,
    YourPlanTable,
    YourPlanList,
  },
  computed: {
    ...mapGetters(['asset', 'bundle']),
  },
};
</script>

<style lang="scss"></style>
