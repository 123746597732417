<template>
  <form @submit="handleSubmit" class="loan-application-form" autocomplete="off">
    <Input
      name="bot-field"
      label="Don’t fill this out if you're human"
      v-model="formData.honeypot"
      autocomplete="random"
      isHoneyPot
    />

    <Margins>
      <b-row>
        <b-col md="6">
          <Input
            :label="`${$t('FORM.FIRST_NAME')} *`"
            name="firstName"
            autocomplete="given-name"
            v-model.trim="formData.firstName"
            :error="errors.first('firstName')"
            v-validate="{
              required: true,
              regex: REGEX.BIGBANK.LV.name,
            }"
            :data-vv-as="$t('FORM.VALIDATOR.FIRST_NAME')"
          />
        </b-col>
        <b-col md="6">
          <Input
            :label="`${$t('FORM.LAST_NAME')} *`"
            name="surname"
            autocomplete="family-name"
            v-model.trim="formData.surname"
            :error="errors.first('surname')"
            v-validate="{
              required: true,
              regex: REGEX.BIGBANK.LV.name,
            }"
            class="margins__mobile-md-double"
            :data-vv-as="$t('FORM.VALIDATOR.LAST_NAME')"
          />
        </b-col>
      </b-row>

      <b-row class="margins__double">
        <b-col md="6">
          <Input
            :label="`${$t('FORM.ID_NUMBER')} *`"
            name="personalIdentityCode"
            placeholder="000000-00000"
            v-model.trim="formData.personalIdentityCode"
            :error="errors.first('personalIdentityCode')"
            v-validate="'required|personalCodeLV'"
            :data-vv-as="$t('FORM.VALIDATOR.ID_NUMBER')"
          />
        </b-col>
        <b-col md="6">
          <Input
            name="dateOfBirth"
            placeholder="yyyy-MM-dd"
            :label="`${$t('FORM.DATE_OF_BIRTH')} *`"
            v-model.trim="formData.dateOfBirth"
            :error="errors.first('dateOfBirth')"
            v-validate="'required|dateOfBirthLV|dateOfBirthLVAge'"
            :data-vv-as="$t('FORM.VALIDATOR.DATE_OF_BIRTH')"
          />
        </b-col>
      </b-row>

      <b-row class="margins__double">
        <b-col md="6">
          <Input
            type="email"
            :label="`${$t('FORM.EMAIL')} *`"
            name="email"
            autocomplete="email"
            v-model.trim="formData.email"
            :error="errors.first('email')"
            v-validate="{ required: true, email: true }"
            :data-vv-as="$t('FORM.VALIDATOR.EMAIL')"
          />
        </b-col>
        <b-col md="6">
          <MobileInput
            ref="mobileInput"
            :default-country="STORE.LV.country.toUpperCase()"
            mode="international"
            name="mainMobileNo"
            :label="`${$t('FORM.MOBILE')} *`"
            dynamic-placeholder
            :preferred-countries="[
              STORE.LV.country.toUpperCase(),
              STORE.LT.country.toUpperCase(),
              STORE.EE.country.toUpperCase(),
            ]"
            valid-characters-only
            disabled-fetching-country
            v-bind:value="numberObject.number.international"
            :error="errors.first('mainMobileNo')"
            v-validate="'required|bigbankPhoneLV'"
            @input="changeMobileNumber"
            @validate="populateNumberObject"
            class="margins__mobile-md-double"
            :data-vv-as="$t('FORM.VALIDATOR.MOBILE')"
          />
        </b-col>
      </b-row>

      <b-row class="margins__double">
        <b-col md="6">
          <Select
            name="maritalStatus"
            :label="`${$t('FORM.MARITAL_STATUS')} *`"
            v-model.trim="formData.maritalStatus"
            :options="maritalStatusOptions"
            :error="errors.first('maritalStatus')"
            v-validate="{ required: true }"
            :data-vv-as="$t('FORM.VALIDATOR.MARITAL_STATUS')"
          />
        </b-col>
        <b-col sm="6">
          <Select
            name="dependents"
            :label="`${$t('FORM.DEPENDENTS')} *`"
            v-model.trim="formData.dependents"
            :options="dependentsOptions"
            :error="errors.first('dependents')"
            v-validate="{ required: true }"
            class="margins__mobile-md-double"
            :data-vv-as="$t('FORM.VALIDATOR.DEPENDENTS')"
          />
        </b-col>
      </b-row>

      <b-row class="margins__double">
        <b-col md="6">
          <Select
            name="citizenCountryCode"
            :disabled-option="true"
            :label="`${$t('FORM.CITIZENSHIP')} *`"
            v-model="citizenCountryCode"
            :options="citizenCountryCodeOptions"
            :error="errors.first('citizenCountryCode')"
            v-validate="{ required: true }"
            class="margins__mobile-md-double"
            :data-vv-as="$t('FORM.VALIDATOR.CITIZENSHIP')"
          />
        </b-col>
      </b-row>

      <Check
        class="margins__double"
        id="pep-checkbox"
        name="customerIsPEP"
        v-model="formData.customerIsPEP"
      >
        {{ $t('FORM.CONFIRM_PERSON_IS_A_PEP') }}
        <Button variant="ghost" width="auto" v-b-toggle="`pep-help`">
          <HelpSVG class="icon" />
        </Button>
      </Check>
      <b-collapse id="pep-help" role="tabpanel">
        <Card color="grey" padding="medium" class="text-small">
          <p v-html="$t('FORM.PEP_CONFIRMATION_HELP_LV')" />
        </Card>
      </b-collapse>
      <Input
        v-if="formData.customerIsPEP"
        type="textarea"
        :label="`${$t('FORM.PEP_COMMENT')} *`"
        name="customerIsPEPComment"
        v-model.trim="formData.customerIsPEPComment"
        :error="errors.first('customerIsPEPComment')"
        v-validate="'required|min:2|max:300'"
        :data-vv-as="$t('FORM.VALIDATOR.PEP_COMMENT')"
      />

      <Separator hasLine size="large" />

      <Check
        :label="privacyPolicyConsent"
        name="privacyConsent"
        v-model="formData.privacyConsent"
        v-validate="{ required: true }"
      />

      <Check
        name="allowRegistryCheck"
        v-model="formData.allowRegistryCheck"
        v-validate="{ required: true }"
      >
        <p class="text-small">
          <i18n path="FORM.ALLOW_REGISTRY_CHECK_LV" :tag="false">
            <span
              class="text-link"
              @click="clickOnConsentHelp($event)"
              v-b-toggle="`registry-help`"
            >
              {{ $t('FORM.ALLOW_REGISTRY_CHECK_LV_BUTTON') }}
            </span>
          </i18n>
        </p>
      </Check>
      <b-collapse id="registry-help" role="tabpanel">
        <Card color="grey" padding="medium" class="text-small">
          <p v-html="$t('FORM.ALLOW_REGISTRY_CHECK_LV_HELP')" />
        </Card>
      </b-collapse>

      <Check
        name="agreeToPersonalDataProcessing"
        v-model="formData.agreeToPersonalDataProcessing"
        v-validate="{ required: true }"
      >
        <p class="text-small">
          <i18n path="FORM.AGREE_TO_PERSONAL_DATA_PROCESSING_LV" :tag="false">
            <span
              class="text-link"
              @click="clickOnConsentHelp($event)"
              v-b-toggle="`personal-data-help`"
            >
              {{ $t('FORM.AGREE_TO_PERSONAL_DATA_PROCESSING_LV_BUTTON') }}
            </span>
          </i18n>
        </p>
      </Check>
      <b-collapse id="personal-data-help" role="tabpanel">
        <Card color="grey" padding="medium" class="text-small">
          <p v-html="personalDataHelp" />
        </Card>
      </b-collapse>

      <div
        v-if="
          errors.first('privacyConsent') ||
          errors.first('allowRegistryCheck') ||
          errors.first('agreeToPersonalDataProcessing')
        "
        class="check__error"
      >
        {{ $t('FORM.VALIDATOR.TERMS') }}
      </div>

      <p class="text-small text-center margins__triple">
        {{ $t('FORM.CREDIT_CHECK_INFO') }}
      </p>

      <div class="text-center">
        <Button type="submit">{{ $t('CONTINUE') }}</Button>
      </div>
    </Margins>
  </form>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import {
  GET_CITIZEN_COUNTRY_CODES,
  SET_LOAN_APPLICATION_DATA,
} from '@/store/types';
import {
  Button,
  Check,
  Input,
  Margins,
  Separator,
  Select,
  MobileInput,
} from '@/components';
import { constants } from '@/mixins';
import { STORE } from '@/constants';
import HelpSVG from '@/assets/images/question-circle-solid.svg';
import Card from '@/components/Card';

export default {
  name: 'LoanApplicationFormLV',
  mixins: [constants],
  components: {
    Input,
    Margins,
    Button,
    Check,
    Separator,
    Select,
    MobileInput,
    HelpSVG,
    Card,
  },
  data() {
    return {
      formData: {
        country: STORE.LV.country.toUpperCase(),
        customerIsPEP: false,
        mainMobileNoCountryCode: '',
        mainMobileNo: {
          number: {
            input: '',
          },
        },
      },
      citizenCountryCode: '',
      numberObject: {
        number: {
          input: '',
          international: '',
        },
      },
    };
  },
  mounted() {
    this.GET_CITIZEN_COUNTRY_CODES();

    this.formData = {
      ...this.formData,
      ...this.removeConsents(this.loanApplicationData),
    };
    this.numberObject.number.international = this.loanApplicationData?.mobile;
  },
  methods: {
    ...mapActions([GET_CITIZEN_COUNTRY_CODES]),
    ...mapMutations([SET_LOAN_APPLICATION_DATA]),
    removeConsents(object) {
      if (!object) {
        return this.formData;
      }

      const {
        privacyConsent,
        allowRegistryCheck,
        agreeToPersonalDataProcessing,
        customerIsPEP,
        ...formData
      } = object; // eslint-disable-line no-unused-vars
      return formData;
    },
    async handleSubmit(e) {
      e.preventDefault();
      this.formData.language = this.language;

      if (this.formData.honeypot) {
        return false;
      }

      const valid = await this.$validator.validateAll();
      if (!valid) {
        return false;
      }

      this.formData.mainMobileNo = this.numberObject.number.significant;
      this.formData.mainMobileNoCountryCode =
        '+' + this.numberObject?.country.dialCode;

      this.formData.citizenCountryCodes = [this.citizenCountryCode];

      const clonedFormData = JSON.parse(JSON.stringify(this.formData));
      clonedFormData.bundleId = this.bundle.id;
      clonedFormData.privacyConsent = !!clonedFormData.privacyConsent;
      clonedFormData.customerIsPEP = !!clonedFormData.customerIsPEP;

      const data = {
        ...clonedFormData,
      };

      this.SET_LOAN_APPLICATION_DATA(data);

      this.$router.push({ name: this.ROUTES.LOAN_APPLICATION_LOADING.name });

      this.$validator.reset();
    },
    changeMobileNumber(numberObject) {
      const international =
        numberObject.number?.international || numberObject.number.input;
      this.numberObject = {
        ...this.numberObject,
        number: {
          ...this.numberObject.number,
          international,
        },
      };
    },
    populateNumberObject(numberObject) {
      if (!this.numberObject.input) {
        this.numberObject = numberObject;
      }
    },
    clickOnConsentHelp(event) {
      event.preventDefault();
    },
  },
  computed: {
    ...mapState([
      'loanApplicationData',
      'language',
      'countryCodes',
      'isLoading',
    ]),
    ...mapGetters(['bundle', 'isAuthenticated']),
    privacyPolicyConsent() {
      const termsAndConditionsFileName = `TermsAndConditions-LV-${this.$i18n.locale.toUpperCase()}`;
      const policyFileName = `PrivacyPolicy${this.$i18n.locale.toUpperCase()}`;
      return `<p>${this.$t('FORM.PRIVACY_CONSENT', [
        `<a href="${window.location.origin}/documents/${termsAndConditionsFileName}.pdf" target="_blank" rel="noopener noreferrer">`,
        `</a>`,
        `<a href="${window.location.origin}/documents/${policyFileName}.pdf" target="_blank" rel="noopener noreferrer">`,
        `</a>`,
      ])}</p>`;
    },
    personalDataHelp() {
      return `<p>${this.$t('FORM.AGREE_TO_PERSONAL_DATA_PROCESSING_LV_HELP', [
        `<a href="https://www.bigbank.lv/klientu-datu-apstrades-principi/" target="_blank" rel="noopener noreferrer">`,
        `</a>`,
      ])}</p>`;
    },
    maritalStatusOptions() {
      return [
        { value: 'SINGLE', label: this.$t('MARITAL_STATUS.SINGLE') },
        { value: 'MARRIED', label: this.$t('MARITAL_STATUS.MARRIED') },
        { value: 'DIVORCED', label: this.$t('MARITAL_STATUS.DIVORCED') },
        { value: 'WIDOW', label: this.$t('MARITAL_STATUS.WIDOW') },
        {
          value: 'COHABITING',
          label: this.$t('MARITAL_STATUS.COHABITING'),
        },
      ];
    },
    dependentsOptions() {
      return [
        { value: '0', label: this.$t('NUMBER_OF_DEPENDENTS.0') },
        { value: '1', label: this.$t('NUMBER_OF_DEPENDENTS.1') },
        { value: '2', label: this.$t('NUMBER_OF_DEPENDENTS.2') },
        { value: '3', label: this.$t('NUMBER_OF_DEPENDENTS.3') },
        { value: '4', label: this.$t('NUMBER_OF_DEPENDENTS.4') },
        { value: '5', label: this.$t('NUMBER_OF_DEPENDENTS.5') },
        { value: '6', label: this.$t('NUMBER_OF_DEPENDENTS.6') },
        { value: '7', label: this.$t('NUMBER_OF_DEPENDENTS.7') },
        { value: '8', label: this.$t('NUMBER_OF_DEPENDENTS.8') },
        { value: '9', label: this.$t('NUMBER_OF_DEPENDENTS.9') },
        { value: '10_OR_MORE', label: this.$t('NUMBER_OF_DEPENDENTS.10+') },
      ];
    },
    citizenCountryCodeOptions() {
      const data = this.countryCodes
        .map((country) => ({
          label: country.translations[this.$i18n.locale.toUpperCase()],
          value: country.code,
        }))
        .sort((a, b) => a.label.localeCompare(b.label, this.$i18n.locale));

      let index = data.findIndex(
        (x) => x.value === STORE.LV.country.toUpperCase(),
      );
      if (index !== -1) {
        data.unshift(data.splice(index, 1)[0]);
      }

      return data;
    },
  },
};
</script>
