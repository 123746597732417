<template>
  <table class="table">
    <thead>
      <tr>
        <th class="text-center">
          {{ $t('BACK_OFFICE_ORDERS.DELIVERY_STORE') }}
        </th>
        <th>{{ $t('BACK_OFFICE_ORDERS.SERIAL') }}</th>
        <th>{{ $t('BACK_OFFICE_ORDERS.PRODUCT_NAME') }}</th>
        <th>{{ $t('BACK_OFFICE_ORDERS.RETAIL_PRICE') }}</th>
        <th>{{ $t('BACK_OFFICE_ORDERS.TOTAL_AMOUNT') }}</th>
        <th>{{ $t('FORM.NAME') }}</th>
        <th>{{ $t('FORM.MOBILE') }}</th>
        <th>{{ $t('FORM.EMAIL') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td
          class="text-center text-wrap"
          v-for="(field, index) in formattedOrderDetails"
          :key="index"
        >
          {{ field }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { formatValues } from '@/mixins';

export default {
  name: 'BackOfficeBundleOrderDetails',
  mixins: [formatValues],
  props: {
    orderDetails: Object,
  },
  computed: {
    formattedOrderDetails() {
      return {
        deliveryStore: this.orderDetails.deliveryStore || '',
        serial: this.orderDetails.serial || '',
        bundleName: this.orderDetails.bundleName || '',
        retailPrice: this.formatPrice(this.orderDetails.retailPrice) || '',
        totalAmount: this.formatPrice(this.orderDetails.totalAmount) || '',
        name: this.orderDetails.name || '',
        mobile: this.orderDetails.mobile || '',
        email: this.orderDetails.email || '',
      };
    },
  },
};
</script>
