<template>
  <div
    :class="[
      'select',
      {
        'has-error': error,
        [`select--${size}`]: size,
        [`select--${theme}`]: theme,
      },
    ]"
  >
    <label :for="name" :class="['select__label', { 'sr-only': labelHidden }]">
      {{ label }}
    </label>
    <div class="select__content">
      <!-- eslint-disable -->
      <select
        class="select__input"
        :id="name"
        :name="name"
        v-model="val"
        :aria-invalid="!!error"
        :aria-describedby="`error-${name}`"
        @input="updateSelect"
        @change="changeSelect"
      >
        <!-- eslint-enable -->
        <option
          v-for="(option, index) in options"
          :key="index"
          :value="option.value"
          :disabled="option.disabled"
        >
          {{ option.label }}
        </option>
      </select>
      <ChevronDownSVG class="icon select__icon" />
    </div>
    <p v-if="error" :id="`error-${name}`" class="select__error">{{ error }}</p>
  </div>
</template>

<script>
import ChevronDownSVG from '@/assets/images/chevron-down-solid.svg';

export default {
  name: 'Select',
  inject: ['$validator'],
  components: {
    ChevronDownSVG,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: String,
    labelHidden: Boolean,
    options: {
      type: Array,
      required: true,
    },
    value: [String, Object, Number],
    error: String,
    size: {
      validator: function (value) {
        return ['extra-small', 'small'].includes(value);
      },
    },
    theme: {
      validator: function (value) {
        return ['dark'].includes(value);
      },
    },
  },
  data() {
    return {
      val: this.value,
    };
  },
  watch: {
    value(val) {
      this.val = val;
    },
  },
  methods: {
    updateSelect(e) {
      this.$emit('input', e.target.value);
    },
    changeSelect(e) {
      this.$emit('change', e.target.value);
    },
  },
};
</script>

<style lang="scss">
.select__label {
  display: block;
  font-size: $font-size-small;
  margin-bottom: rem(8px);
}

.select__content {
  position: relative;
}

.select__input {
  display: block;
  background: transparent;
  border-radius: $border-radius;
  border: rem(1px) solid $color-grey-border;
  font-size: $font-size-base-rem;
  font-weight: $font-weight-bold;
  padding: rem(16px);
  padding-right: rem(38px);
  width: 100%;
  appearance: none;
  cursor: pointer;

  .select--small & {
    padding: rem(8px);
    padding-right: rem(24px);
  }

  .select--extra-small & {
    padding: rem(8px);
    padding-right: rem(24px);
    font-size: $font-size-extra-small;
    font-weight: $font-weight;
    line-height: 1.5;
  }

  .select--dark & {
    border-color: transparent;
    color: $color-grey-light;
    opacity: 0.8;
    background: $color-black-light;

    &:hover {
      opacity: 1;
    }
  }

  .select.has-error & {
    border-color: $color-error;
  }

  &:focus {
    border-color: $color-primary;
    box-shadow: 0 0 0 4px rgba($color-primary, 0.6);
    outline: none;
  }
}

.select__icon {
  position: absolute;
  top: 50%;
  right: rem(16px);
  transform: translateY(-50%);
  font-size: $font-size-small;
  pointer-events: none;

  > path {
    fill: $color-black;
    opacity: 0.8;

    .select:hover & {
      opacity: 1;
    }

    .select--dark & {
      fill: $color-grey-light;
    }
  }

  .select--small & {
    right: rem(8px);
  }

  .select--extra-small & {
    right: rem(8px);
    font-size: $font-size-extra-small;
  }
}

.select__error {
  font-size: $font-size-small;
  color: $color-error;
  margin-top: rem(8px);
}
</style>
