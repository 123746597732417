<template>
  <div
    :class="[
      'mobile-input',
      { 'has-error': error },
      { 'is-readonly': readonly },
    ]"
  >
    <label :for="name" v-if="label" class="mobile-input__label">
      {{ label }}
      <p v-if="info" class="mobile-input__info">{{ info }}</p>
    </label>
    <VueTelInput
      :value="value"
      :defaultCountry="defaultCountry"
      :mode="mode"
      :name="name"
      :inputId="name"
      :dynamicPlaceholder="dynamicPlaceholder"
      :preferredCountries="preferredCountries"
      :validCharactersOnly="validCharactersOnly"
      :disabledFetchingCountry="disabledFetchingCountry"
      :onlyCountries="onlyCountries"
      inputClasses="mobile-input__input"
      wrapperClasses="mobile-input__content"
      :aria-invalid="!!error"
      :aria-describedby="`error-${name}`"
      @input="updateInput"
      @validate="validateInput"
    />
    <p v-if="error" :id="`error-${name}`" class="mobile-input__error">
      {{ error }}
    </p>
  </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';

export default {
  name: 'MobileInput',
  inject: ['$validator'],
  components: {
    VueTelInput,
  },
  props: {
    label: String,
    info: String,
    value: String,
    error: String,
    readonly: Boolean,
    defaultCountry: String,
    mode: String,
    name: {
      type: String,
      required: true,
    },
    dynamicPlaceholder: Boolean,
    preferredCountries: Array,
    validCharactersOnly: Boolean,
    disabledFetchingCountry: Boolean,
    onlyCountries: Array,
  },
  methods: {
    updateInput(numberAsString, numberAsObject) {
      this.$emit('input', numberAsObject);
    },
    validateInput(numberAsObject) {
      this.$emit('validate', numberAsObject);
    },
  },
};
</script>

<style lang="scss">
.mobile-input__label {
  display: block;
  font-size: $font-size-small;
  margin-bottom: rem(8px);
}

.mobile-input__content {
  position: relative;
  border-radius: $border-radius !important;
  border: rem(1px) solid $color-grey-border !important;

  .mobile-input.has-error & {
    border-color: $color-error !important;
  }

  .mobile-input.is-readonly & {
    background: $color-grey-border;

    &:focus {
      border-color: $color-grey-border !important;
    }
  }
}

.mobile-input__input {
  display: block;
  border-radius: $border-radius !important;
  font-size: $font-size-base-rem;
  font-weight: $font-weight-bold;
  padding: rem(16px);
  width: 100%;

  &:focus {
    border-color: $color-primary;
    box-shadow: 0 0 0 4px rgba($color-primary, 0.6);
    outline: none;
  }
}

.mobile-input__input::placeholder {
  color: $color-grey;
  font-weight: $font-weight-thin;
  font-size: $font-size-base-rem;
}

.mobile-input__info {
  color: $color-grey-dark;
  font-size: $font-size-extra-small;
  margin-top: rem(4px);
}

.mobile-input__error {
  color: $color-error;
  font-size: $font-size-small;
  margin-top: rem(8px);
}

.vti__dropdown {
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;

  &:hover {
    background-color: $color-grey-light !important;
  }
}
</style>
