export const API_BASE_URL = process.env.VUE_APP_API_URL;
export const BIGBANK_EMAIL_EE = process.env.VUE_APP_BIGBANK_EMAIL_EE;
export const BIGBANK_EMAIL_LV = process.env.VUE_APP_BIGBANK_EMAIL_LV;
export const BIGBANK_EMAIL_LT = process.env.VUE_APP_BIGBANK_EMAIL_LT;
export const BIGBANK_PHONE_EE = process.env.VUE_APP_BIGBANK_PHONE_EE;
export const BIGBANK_PHONE_LV = process.env.VUE_APP_BIGBANK_PHONE_LV;
export const BIGBANK_PHONE_LT = process.env.VUE_APP_BIGBANK_PHONE_LT;
export const INBANK_EMAIL_EE = process.env.VUE_APP_INBANK_EMAIL_EE;
export const INBANK_EMAIL_LV = process.env.VUE_APP_INBANK_EMAIL_LV;
export const INBANK_EMAIL_LT = process.env.VUE_APP_INBANK_EMAIL_LT;
export const INBANK_EMAIL_PL = process.env.VUE_APP_INBANK_EMAIL_PL;
export const INBANK_PHONE_EE = process.env.VUE_APP_INBANK_PHONE_EE;
export const INBANK_PHONE_LV = process.env.VUE_APP_INBANK_PHONE_LV;
export const INBANK_PHONE_LT = process.env.VUE_APP_INBANK_PHONE_LT;
export const INBANK_PHONE_PL = process.env.VUE_APP_INBANK_PHONE_PL;
export const SUPPORT_EMAIL = process.env.VUE_APP_SUPPORT_EMAIL;
export const INBANK_ENABLED = process.env.VUE_APP_INBANK_API_ENABLED === 'true';

export const ROUTES = {
  ROOT: {
    path: '/',
    name: 'root',
  },
  HOME: {
    path: '/product/:productCode',
    name: 'home',
  },
  MAINTENANCE: {
    path: '/maintenance',
    name: 'maintenance',
  },
  FAQ: {
    path: '/faq',
    name: 'faq',
  },
  LOAN_APPLICATION: {
    path: '/apply/loan-application',
    name: 'loan-application',
  },
  LOAN_APPLICATION_SHORT: {
    path: '/a',
    name: 'loan-application',
  },
  LOAN_APPLICATION_LOADING: {
    path: '/apply/loan-application/loading',
    name: 'loan-application-loading',
  },
  LOAN_APPLICATION_COMPLETE: {
    path: '/apply/loan-application/complete/*',
    name: 'loan-application-complete',
  },
  DECISION_SUCCESS: {
    path: '/decision/success',
    name: 'decision-success',
  },
  DECISION_PENDING: {
    path: '/decision/pending',
    name: 'decision-pending',
  },
  DECISION_CANCELLED: {
    path: '/decision/cancelled/*',
    name: 'decision-cancelled',
  },
  DECISION_DENIED: {
    path: '/decision/denied',
    name: 'decision-denied',
  },
  BACK_OFFICE: {
    path: '/back-office',
    name: 'back-office',
  },
  BACK_OFFICE_LOGIN: {
    path: '/login',
    name: 'back-office-login',
  },
  BACK_OFFICE_REGISTRATION: {
    path: '/registration/:invitationId',
    name: 'back-office-registration',
  },
  BACK_OFFICE_PASSWORD_RESET: {
    path: '/password-reset/:tokenId',
    name: 'back-office-password-reset',
  },
  BACK_OFFICE_PASSWORD_LOST: {
    path: '/password-lost',
    name: 'back-office-password-lost',
  },
  PAGE_404: {
    path: '/404',
    name: '404',
  },
  BACK_OFFICE_APPLICATION: {
    path: '/back-office/application',
    name: 'back-office-application',
  },
  BUNDLES: {
    path: '/back-office/bundles',
    name: 'bundles',
  },
  USER_MANAGEMENT: {
    path: '/back-office/user-management',
    name: 'user-management',
  },
};

export const SERVICE_TYPES = {
  SERVICE: 'service',
  INSURANCE: 'insurance',
  INSTALLATION: 'installation',
};

export const PERSISTED_STORE_KEY = 'idream-persisted-store';

export const STEPS = {
  select: 0,
  apply: 1,
  clientRedirected: 2,
  decision: 3,
};

export const REGEX = {
  INBANK: {
    PL: {
      name: /^\D{1,100}$/i,
      mobile:
        /^(\+4845|\+4850|\+4851|\+4853|\+4857|\+4860|\+4866|\+4869|\+4872|\+4873|\+4878|\+4879|\+4888)\d{7}$/,
    },
  },
  BALTICSANDPOLISH: {
    mobile:
      /^((\+3725|\+3712|\+3706)\d{1,15}|(\+4845|\+4850|\+4851|\+4853|\+4857|\+4860|\+4866|\+4869|\+4872|\+4873|\+4878|\+4879|\+4888)\d{7})$/,
  },
  number: '^\\d+$',
  alphaNumEN: '^[0-9A-Za-z]{10,12}$',
  companyEmailPattern: /^[A-Z0-9._+-]+@(idream\.pl|fairown\.com)/i,
};

export const DECISION_TYPES = {
  APPROVED: 'APPROVED',
  PROCESSING: 'PROCESSING',
  REJECTED: 'REJECTED',
  ANNULLED: 'ANNULLED',
  ACTIVATED: 'ACTIVATED',
  PAYOUT: 'PAYOUT',
  CONTRACT_SIGNED: 'CONTRACT_SIGNED',
  SIGN_CONTRACT: 'SIGN_CONTRACT',
  ADDITIONAL_DATA_NEEDED: 'ADDITIONAL_DATA_NEEDED',
  PENDING: 'PENDING',
  CANCELLED: 'CANCELLED',
  DECLINED: 'DECLINED',
  EXPIRED: 'EXPIRED',
  GRANTED: 'GRANTED',
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR',
  INIT_AUTH: 'INIT_AUTH',
  POST_RECEIVED: 'POST_RECEIVED',
  WAITING_FOR_PERMISSION: 'WAITING_FOR_PERMISSION',
  PERMISSION_SIGNED: 'PERMISSION_SIGNED',
  VALIDATING_INFO: 'VALIDATING_INFO',
  WAITING_FOR_SIF_CONFIRM: 'WAITING_FOR_SIF_CONFIRM',
  WAITING_FOR_DEAL_SIGN: 'WAITING_FOR_DEAL_SIGN',
  NEED_HELP: 'NEED_HELP',
  NO_LOAN_POSSIBLE: 'NO_LOAN_POSSIBLE',
  CLIENT_CANCELED: 'CLIENT_CANCELED',
  SIGNED_DEAL: 'SIGNED_DEAL',
  SIGNED_DEAL_NO_ADVANCE: 'SIGNED_DEAL_NO_ADVANCE',
  SIGNED_DEAL_YOUR_ADVANCE: 'SIGNED_DEAL_YOUR_ADVANCE',
  SIGNED_DEAL_OFFLINE: 'SIGNED_DEAL_OFFLINE',
};

export const SIGNATURE_STATUSES = {
  CREATED: 'CREATED',
  NOTIFIED: 'NOTIFIED',
  STARTED: 'STARTED',
  COMPLETE: 'COMPLETE',
  CANCELLED: 'CANCELLED',
  UNKNOWN: 'UNKNOWN',
};

export const ORDER_STATUSES = {
  CREATED: 'CREATED',
  // PENDING: 'PENDING',
  SUBMITTED: 'SUBMITTED',
  // COMPLETED: 'COMPLETED',
  HANDED_OVER: 'HANDED_OVER',
  ANNULLED: 'ANNULLED',
  REJECTED: 'REJECTED',
  GRANTED: 'GRANTED',
};

export const LOCALES = {
  EN: 'en',
  PL: 'pl',
};

export const STORE = {
  PL: {
    name: 'Apple',
    lang: 'pl',
    country: 'pl',
    availableLocales: [LOCALES.PL, LOCALES.EN],
    mapConfig: {
      centerPosition: { lat: 61.7529118, lng: 15.1709969 },
      zoom: 6,
    },
  },
  EE: {
    name: 'Apple',
    lang: 'pl',
    country: 'pl',
    availableLocales: [LOCALES.PL, LOCALES.EN],
    mapConfig: {
      centerPosition: { lat: 61.7529118, lng: 15.1709969 },
      zoom: 6,
    },
  },
};

export const COUNTRY = {
  PL: 'pl',
};

export const SELLER = {
  IDREAM: 'iDream',
};

export const DELIVERY_TYPES = {
  EMPTY: 'EMPTY',
  HOME: 'HOME',
  STORE: 'STORE',
};

export const USER_ROLE = {
  ADMIN: 'ADMIN',
  EMPLOYEE: 'EMPLOYEE',
  EMPLOYEE_ADMIN: 'EMPLOYEE_ADMIN',
};

export const CURRENCY_CODE = {
  PLN: 'zł',
};
