<template>
  <div class="apply">
    <Container v-if="!isLoading">
      <Margins>
        <h1 class="h2">{{ $t('HOME.TITLE') }}</h1>

        <b-row align-h="center" class="margins__triple">
          <b-col cols="8" sm="5" md="3" v-if="!isStorePolish">
            <ApplyAssetCard />
          </b-col>
          <b-col md="9">
            <Card class="margins__mobile-md-double">
              <Margins>
                <Product v-if="isStorePolish" />
                <YourPlan v-else />

                <MobileInput
                  v-if="isStorePolishAndUserLoggedIn"
                  ref="mobileInput"
                  :default-country="STORE.PL.country.toUpperCase()"
                  mode="international"
                  name="mainMobileNo"
                  :label="`${$t('FORM.MOBILE')} *`"
                  dynamic-placeholder
                  :onlyCountries="[
                    STORE.PL.country.toUpperCase(),
                    STORE.EE.country.toUpperCase(),
                  ]"
                  valid-characters-only
                  disabled-fetching-country
                  :value="numberObject.number.international"
                  :error="errors.first('mainMobileNo')"
                  v-validate="'required'"
                  @input="changeMobileNumber"
                  @validate="populateNumberObject"
                  class="margins__mobile-md-double"
                  :data-vv-as="$t('FORM.VALIDATOR.MOBILE')"
                />

                <b-row
                  align-v="center"
                  align-h="center"
                  class="margins__double"
                >
                  <b-col cols="auto">
                    <template v-if="isStorePolishAndUserLoggedIn">
                      <Button
                        v-if="!smsSent"
                        :loading="sendSmsButtonLoading"
                        @click="handleSMSSend()"
                      >
                        {{ $t('SEND_CONTINUE_LINK_SMS_TO_CUSTOMER') }}
                      </Button>
                      <span v-else>
                        {{ $t('CONTINUE_LINK_SMS_TO_CUSTOMER_SENT') }}
                      </span>
                      <Button
                        class="ml-2 mt-2 mb-2"
                        @click="copyApplicationLink()"
                      >
                        {{ $t('COPY_APPLICATION_LINK') }}
                      </Button>
                      <p v-if="linkCopied" class="text-dark text-right">
                        {{ $t('LINK_COPIED') }}
                      </p>
                    </template>
                    <template v-else>
                      <Button :to="{ name: ROUTES.LOAN_APPLICATION.name }">
                        {{ $t('APPLY') }}
                      </Button>
                    </template>
                  </b-col>
                </b-row>
              </Margins>
            </Card>
          </b-col>
        </b-row>
      </Margins>
    </Container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import {
  RESET_STORE,
  GET_BUNDLE_FROM_PRODUCT_CODE,
  SEND_CUSTOMER_CONTINUE_LINK,
} from '@/store/types';
import { Container, Card, Margins, Button, MobileInput } from '@/components';
import { ApplyAssetCard, YourPlan } from '@/containers/Apply';
import { Product } from '@/containers';
import { constants } from '@/mixins';
import { STORE } from '@/constants';

export default {
  name: 'Home',
  mixins: [constants],
  components: {
    Container,
    Card,
    Margins,
    Button,
    ApplyAssetCard,
    YourPlan,
    Product,
    MobileInput,
  },
  data() {
    return {
      formData: {
        country: STORE.PL.country.toUpperCase(),
        locale: 'pl-PL',
        customerData: {},
        customerContactData: {},
        purchase: {},
      },
      numberObject: {
        number: {
          input: '',
          international: '',
        },
      },
      smsSent: false,
      sendSmsButtonLoading: false,
      linkCopied: false,
    };
  },
  created() {
    if (this.$route.params.productCode) {
      this.GET_BUNDLE_FROM_PRODUCT_CODE(this.$route.params.productCode);
      if (this.$route.params.productCode !== this.productCode) {
        this.RESET_STORE();
      }
    }
  },
  methods: {
    ...mapActions([GET_BUNDLE_FROM_PRODUCT_CODE, SEND_CUSTOMER_CONTINUE_LINK]),
    ...mapMutations([RESET_STORE]),
    changeMobileNumber(numberObject) {
      const international =
        numberObject.number?.international || numberObject.number.input;
      this.numberObject = {
        ...this.numberObject,
        number: {
          ...this.numberObject.number,
          international,
        },
      };
    },
    populateNumberObject(numberObject) {
      if (!this.numberObject.input) {
        this.numberObject = numberObject;
      }
    },
    async handleSMSSend() {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return false;
      }

      this.sendSmsButtonLoading = true;
      const bundleId = this.bundle.id;
      const mobile = this.numberObject.number.international.replace(/\s/g, '');
      try {
        await this.SEND_CUSTOMER_CONTINUE_LINK({
          bundleId: bundleId,
          phoneNr: mobile,
        });
        this.smsSent = true;
      } finally {
        this.sendSmsButtonLoading = false;
      }
    },
    copyApplicationLink() {
      const url = `https://idream.fairown.com/product/${this.bundle.productCode}?store=iDream`;
      navigator.clipboard.writeText(url);
      this.linkCopied = true;
    },
  },
  computed: {
    ...mapState(['isLoading', 'productCode']),
    ...mapGetters(['isStorePolish', 'bundle', 'isBackofficeUserLoggedIn']),
    isStorePolishAndUserLoggedIn() {
      return this.isStorePolish && this.isBackofficeUserLoggedIn;
    },
  },
};
</script>
