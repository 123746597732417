import { REGEX } from '@/constants';
import dayjs from 'dayjs';

export {
  validatePersonalCodePL,
  validatePersonalCodeEEAndLT,
  validatePersonalCodeLV,
  validateBalticsAndPolishPhone,
  validateBigbankPhoneEE,
  validateBigbankPhoneLV,
  validateBigbankPhoneLT,
  validateInbankPhoneEE,
  validateInbankPhoneLV,
  validateInbankPhoneLT,
  validateInbankPhonePL,
  dateOfBirthLV,
  dateOfBirthLVAge,
  isUsernameCompanyEmail,
};

function validatePersonalCodePL(idCode) {
  // Regex the format 12345678901
  const regexResult = /^[0-9]{11}$/.test(idCode);
  if (!regexResult) {
    return false;
  }
  const multiplier = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
  let checkSum = 0;

  //muliply idCode digits by checkSum digits
  for (let i = 0; i < 10; i++) {
    checkSum += multiplier[i] * idCode.charAt(i);
  }

  checkSum = checkSum % 10 === 0 ? 0 : 10 - (checkSum % 10);
  return checkSum === parseInt(idCode.charAt(10));
}

function validatePersonalCodeEEAndLT(idCode) {
  // Regex the format 12345678901
  const regexResult = /^[0-9]{11}$/.test(idCode);
  if (!regexResult) {
    return false;
  }

  let gender = parseInt(idCode.charAt(0), 10),
    year = parseInt(idCode.substr(1, 2), 10),
    month = parseInt(idCode.substr(3, 2), 10),
    day = parseInt(idCode.substr(5, 2), 10),
    century = gender % 2 === 0 ? 17 + gender / 2 : 17 + (gender + 1) / 2;
  year = century * 100 + year;
  const isValidDate = birthDate(year, month, day, true);
  if (!isValidDate) {
    return false;
  }

  let multiplier_1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1];
  let multiplier_2 = [3, 4, 5, 6, 7, 8, 9, 1, 2, 3];

  let total = 0;

  /* Do first run. */
  for (let i = 0; i < 10; i++) {
    total += idCode.charAt(i) * multiplier_1[i];
  }
  let mod = total % 11;

  /* If modulus is ten we need second run. */
  if (10 === mod) {
    total = 0;
    for (let i = 0; i < 10; i++) {
      total += idCode.charAt(i) * multiplier_2[i];
    }
    mod = total % 11;

    /* If modulus is still ten revert to 0. */
    if (10 === mod) {
      mod = 0;
    }
  }

  let controlDigit = idCode.charAt(10);
  return +controlDigit === mod;
}

function validatePersonalCodeLV(idCodeWithDash) {
  // Regex the format 12346-12345
  const oldFormatPattern = '^(?!32)[0-9]{6}-[0-9]{5}$';
  const newFormatPattern = '^32[0-9]{4}-[0-9]{5}$';
  const combinedFormatPattern = RegExp(
    oldFormatPattern + '|' + newFormatPattern,
  );

  if (!combinedFormatPattern.test(idCodeWithDash)) {
    return false;
  }

  let idCode = idCodeWithDash.replace(/\D/g, '');

  // Check personal code
  let sum = 0;
  let weight = [10, 5, 8, 4, 2, 1, 6, 3, 7, 9];
  for (let i = 0; i < 10; i++) {
    sum += parseInt(idCode.charAt(i), 10) * weight[i];
  }
  sum = ((sum + 1) % 11) % 10;

  let controlDigit = idCode.charAt(10);
  return +controlDigit === sum;
}

function extractPhoneNumber(phoneObjectOrNumber) {
  if (!phoneObjectOrNumber.number) {
    return phoneObjectOrNumber;
  } else {
    return phoneObjectOrNumber.number.international;
  }
}

function extractPhoneNumberEE(phoneObjectOrNumber) {
  const phoneNumber = extractPhoneNumber(phoneObjectOrNumber);
  return phoneNumber?.replace(/\s/g, '').replace('+', '');
}

function extractDialCodeAndPhoneNumberLV(phoneObjectOrNumber) {
  const phoneNumberWithDialCode = extractPhoneNumber(phoneObjectOrNumber);

  if (!phoneNumberWithDialCode) {
    return {
      dialCode: '',
      phoneNumber: '',
    };
  }

  const extractedDialCode = phoneNumberWithDialCode
    .substr(0, phoneNumberWithDialCode.indexOf(' '))
    .replace('+', '');

  const extractedNumber = phoneNumberWithDialCode
    .substr(phoneNumberWithDialCode.indexOf(' ') + 1)
    .replace(/\s/g, '');

  return {
    dialCode: extractedDialCode,
    phoneNumber: extractedNumber,
  };
}

function extractPhoneNumberWithoutSpace(phoneObjectOrNumber) {
  const phoneNumber = extractPhoneNumber(phoneObjectOrNumber);
  return phoneNumber?.replace(/\s/g, '');
}

function validateBalticsAndPolishPhone(phoneObjectOrNumber) {
  const phoneNumber = extractPhoneNumberWithoutSpace(phoneObjectOrNumber);
  return REGEX.BALTICSANDPOLISH.mobile.test(phoneNumber);
}

function validateBigbankPhoneEE(phoneObjectOrNumber) {
  const phoneNumber = extractPhoneNumberEE(phoneObjectOrNumber);
  return REGEX.BIGBANK.EE.mobile.test(phoneNumber);
}

function validateBigbankPhoneLV(phoneObjectOrNumber) {
  const lvDialCode = '371';

  const extractionResult = extractDialCodeAndPhoneNumberLV(phoneObjectOrNumber);
  const phoneNumber = extractionResult.phoneNumber;
  const dialCode = extractionResult.dialCode;

  if (dialCode === lvDialCode) {
    return REGEX.BIGBANK.LV.mobile.test(phoneNumber);
  } else {
    return REGEX.BIGBANK.LV.foreignMobile.test(phoneNumber);
  }
}

function validateBigbankPhoneLT(phoneObjectOrNumber) {
  const phoneNumber = extractPhoneNumberWithoutSpace(phoneObjectOrNumber);
  return REGEX.BIGBANK.LT.mobile.test(phoneNumber);
}

function validateInbankPhoneEE(phoneObjectOrNumber) {
  const phoneNumber = extractPhoneNumberEE(phoneObjectOrNumber);
  return REGEX.INBANK.EE.mobile.test(phoneNumber);
}

function validateInbankPhoneLV(phoneObjectOrNumber) {
  const extractionResult = extractDialCodeAndPhoneNumberLV(phoneObjectOrNumber);
  const phoneNumber = extractionResult.phoneNumber;
  const dialCode = extractionResult.dialCode;
  return REGEX.INBANK.LV.mobile.test(dialCode + phoneNumber);
}

function validateInbankPhoneLT(phoneObjectOrNumber) {
  const phoneNumber = extractPhoneNumberWithoutSpace(phoneObjectOrNumber);
  return REGEX.INBANK.LT.mobile.test(phoneNumber);
}

function validateInbankPhonePL(phoneObjectOrNumber) {
  const phoneNumber = extractPhoneNumberWithoutSpace(phoneObjectOrNumber);
  return REGEX.INBANK.PL.mobile.test(phoneNumber);
}

/**
 * Validate a date
 *
 * @param {Number} year The full year in 4 digits
 * @param {Number} month The month number
 * @param {Number} day The day number
 * @param {Boolean} [notInFuture] If true, the date must not be in the future
 * @returns {Boolean}
 */
function birthDate(year, month, day, notInFuture) {
  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    return false;
  }
  if (day.length > 2 || month.length > 2 || year.length > 4) {
    return false;
  }

  day = parseInt(day, 10);
  month = parseInt(month, 10);
  year = parseInt(year, 10);

  if (year < 1900 || year > 9999 || month <= 0 || month > 12) {
    return false;
  }
  var numDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  // Update the number of days in Feb of leap year
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    numDays[1] = 29;
  }

  // Check the day
  if (day <= 0 || day > numDays[month - 1]) {
    return false;
  }

  if (notInFuture === true) {
    var currentDate = new Date(),
      currentYear = currentDate.getFullYear(),
      currentMonth = currentDate.getMonth(),
      currentDay = currentDate.getDate();
    return (
      year < currentYear ||
      (year === currentYear && month - 1 < currentMonth) ||
      (year === currentYear && month - 1 === currentMonth && day < currentDay)
    );
  }

  return true;
}

function dateOfBirthLV(date, format = 'YYYY-MM-DD') {
  return dayjs(date, format).format(format) === date;
}

function dateOfBirthLVAge(date) {
  const ageDate = dayjs(date);
  const now = dayjs();
  const age = now.diff(ageDate, 'y', true);
  return age >= 20 && age < 74;
}

function isUsernameCompanyEmail(username) {
  return REGEX.companyEmailPattern.test(username);
}
